import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";

const FormErrors = ({ form }: { form: any }) => {
  return (
    <Alert mb={8} status="error">
      <AlertIcon />

      <Box flex="1">
        <AlertTitle>Form cannot save due to the following errors:</AlertTitle>
        <AlertDescription display="block">
          <UnorderedList>
            {Object.entries(form.errors).map(([field, errorMessage]) => (
              <ListItem key={field}>
                {field}: {errorMessage}
              </ListItem>
            ))}
          </UnorderedList>
        </AlertDescription>
      </Box>
    </Alert>
  );
};

export default FormErrors;
