import { Flex } from "@chakra-ui/layout";
import { FlexProps } from "@chakra-ui/react";

interface PageContentHeaderProps extends FlexProps {}

const PageContentHeader = ({
  justify = "space-between",
  align = "center",
  marginY = 4,
  ...rest
}: PageContentHeaderProps) => {
  return (
    <Flex
      data-testid="page-content-header"
      justify={justify}
      align={align}
      marginX="auto"
      marginY={marginY}
      width="full"
      mt="0!important"
      {...rest}
    />
  );
};

export default PageContentHeader;
