import React, { useState, ReactText } from "react";
import {
  Radio as ChakraRadio,
  RadioProps as ChakraRadioProps,
  RadioGroup as ChakraRadioGroup,
  RadioGroupProps as ChakraRadioGroupProps,
  FormControl,
  Stack,
  Box,
} from "@chakra-ui/react";

import Label from "components/forms/label/label";
import HelperText from "components/forms/helper-text/helper-text";
import ErrorMessage from "components/forms/error-message/error-message";

import { dashCase } from "utilities";

import { LabelValuePair } from "types";

interface RadioProps extends ChakraRadioProps {
  label?: string;
}

const Radio = ({ size, label, ...rest }: RadioProps) => {
  const isLarge = size === "lg";
  const isSmall = size === "sm";
  const radioSize = isSmall ? 6 : isLarge ? 10 : 8;
  const checkmarkSize = isSmall ? 3 : isLarge ? 6 : 4;

  const radioAppearance = {
    width: radioSize,
    height: radioSize,
    boxShadow: "inner",
    borderRadius: "100%",
    whiteSpace: "nowrap",
  };

  return (
    <ChakraRadio
      data-testid="radio"
      colorScheme="gray"
      spacing={isLarge ? 4 : 2}
      _checked={{
        borderColor: "transparent",
        _before: {
          content: "''",
          display: "inline-block",
          position: "relative",
          width: checkmarkSize,
          height: checkmarkSize,
          borderRadius: "full",
          background: "black",
        },
      }}
      _focus={{
        borderStyle: "2px solid",
        borderColor: "forms.focusBorderColor",
      }}
      _invalid={{
        boxShadow: "error",
      }}
      sx={{ ...radioAppearance }}
      {...rest}>
      <Box fontSize={isLarge ? "md" : "sm"}>{label}</Box>
    </ChakraRadio>
  );
};

export default Radio;

type RadioOption = LabelValuePair;

interface RadioGroupProps extends ChakraRadioGroupProps {
  name: string;
  children: RadioOption[];
  label?: string;
  helperText?: string;
  errorMessage?: string;
  isInvalid?: boolean;
  isDisabled?: boolean;
}

export const RadioGroup = ({
  name,
  label,
  helperText,
  errorMessage,
  isInvalid = false,
  isDisabled = false,
  size,
  children,
  ...rest
}: RadioGroupProps) => {
  const [selectedValue, setSelectedValue] = useState<ReactText>();

  return (
    <FormControl data-testid="radio-group" isInvalid={isInvalid} isDisabled={isDisabled}>
      {label && <Label value={label} fontWeight="bold" />}
      <ChakraRadioGroup
        as={Stack}
        name={name}
        onChange={setSelectedValue}
        value={selectedValue}
        {...rest}>
        {children.map((option) => (
          <Radio
            data-testid={`radio-${dashCase(option.label)}`}
            key={option.value}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            size={size}
            label={option.label}
            value={option.value}
          />
        ))}
      </ChakraRadioGroup>
      {helperText && <HelperText message={helperText} />}
      {isInvalid && errorMessage ? (
        <ErrorMessage message={errorMessage} />
      ) : (
        <ErrorMessage message="Invalid selection" />
      )}
    </FormControl>
  );
};
