import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";
import toast from "components/partials/toast/toast";

import { useCurrentClient, useCurrentCampaign, useCurrentTouchpoint } from "state/ducks";
import TouchpointVersion, { TouchpointVersionAttributes } from "models/touchpoint-version";

import { ClientReviewStatus } from "types";

interface ApproveAsClientModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedVersion?: TouchpointVersionAttributes;
  setSelectedVersion: (version: TouchpointVersionAttributes) => void;
}

export const ApproveAsClientModal = ({
  isOpen,
  onClose,
  selectedVersion,
  setSelectedVersion,
}: ApproveAsClientModalProps) => {
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const currentTouchpoint = useCurrentTouchpoint();

  const approveTouchpoint = async (): Promise<boolean> => {
    try {
      if (
        !currentClient?.id ||
        !currentCampaign?.id ||
        !currentTouchpoint?.id ||
        !selectedVersion?.id
      ) {
        throw new Error("Missing required information to approve touchpoint.");
      }
      await TouchpointVersion.patchTouchpointStatus({
        clientId: currentClient.id,
        campaignId: currentCampaign.id,
        touchpointId: currentTouchpoint.id,
        touchpointVersionId: selectedVersion.id,
        status: ClientReviewStatus.APPROVED,
      });
      return true;
    } catch (err) {
      console.error("Error in approveTouchpoint:", err);
      return false;
    }
  };

  const approveTouchpointAsClient = async (
    selectedVersion?: TouchpointVersionAttributes
  ): Promise<void> => {
    const isApproved = await approveTouchpoint();

    if (isApproved && selectedVersion) {
      setSelectedVersion({
        ...selectedVersion,
        status: ClientReviewStatus.APPROVED,
      });

      onClose();
      toast.success({ title: "Approval successfully completed" });
    } else {
      // If it fails, show a generic error message to the user.
      // A console.error message will be logged by approveTouchpoint()
      toast.error({
        title: "Failed to approve touchpoint",
        message: "An error occurred while updating the touchpoint.",
        autoClose: false,
      });
    }
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      modalType="warning"
      headline="Confirm 'Approve as a Client' action?"
      message="Are you sure you want to approve the touchpoint version on behalf of the client?"
      confirmButtonText="Approve as a Client"
      onConfirm={() => approveTouchpointAsClient(selectedVersion)}
    />
  );
};
