import { useHistory, Switch, Redirect, useRouteMatch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box } from "@chakra-ui/react";

import { RouteMatchTypes } from "containers/admin/clients/campaign/campaign-settings-page/campaign-settings-page";
import BasicInfo from "containers/admin/clients/client/components/basic-info/basic-info";
import EmailTypes from "containers/admin/clients/client/components/email-types/email-types";
import { H2 } from "components/partials/typography/typography";
import ToggleButtonGroup from "components/partials/toggle-button-group/toggle-button-group";
import toast from "components/partials/toast/toast";

import { setCurrentClient, useCurrentClient } from "state/ducks/clients";
import { WithMaybePersisted } from "models/model";
import Client, { ClientAttributes } from "models/client";

import { getErrorMessage } from "utilities";
import { Route as AppRoute } from "utilities/app-routes";

import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";

const ProfileEditPage = ({ baseUrl }: { baseUrl: string }) => {
  const client = useCurrentClient();
  const dispatch = useDispatch();
  const history = useHistory();
  let { path }: RouteMatchTypes = useRouteMatch();

  usePagePermission(Permission.PERM_CLIENT_WRITE);

  const updateClient = (attributes: WithMaybePersisted<ClientAttributes, "primaryContact">) => {
    Client.replace(attributes)
      .then(async (_response) => {
        const clientAfterUpdating = await Client.find(attributes.id);
        dispatch(setCurrentClient(clientAfterUpdating.attributes));
        toast.success({
          title: "Client updated",
        });
        history.push({
          pathname: `/clients/${clientAfterUpdating.id}/profile`,
        });
      })
      .catch((err) => {
        toast.error({
          title: "Failed to save client",
          message: getErrorMessage(err?.response?.data),
        });
      });
  };

  const cancelEditClient = () => {
    history.push(`/clients/${client.id}/profile`);
  };

  const changeTab = (option: string) => {
    if (option === "Basic information") {
      history.push(baseUrl + AppRoute.basic);
    }
    if (option === "Email types") {
      history.push(baseUrl + AppRoute.emailTypes);
    }
  };

  const currentPage = () => {
    if (history.location.pathname.endsWith(AppRoute.basic)) {
      return "Basic information";
    } else {
      return "Email types";
    }
  };

  return (
    <>
      <H2 className="mb-8 pt-5">Edit Profile</H2>
      <Box>
        <ToggleButtonGroup
          groupName="edit-type-toggle"
          options={["Basic information", "Email types"]}
          value={currentPage()}
          onChange={(option) => {
            changeTab(option);
          }}
          mb={8}
        />
      </Box>
      <Switch>
        <Redirect exact from={path} to={path + AppRoute.basic} />
        <Route path={path + AppRoute.basic}>
          <BasicInfo
            client={client}
            onSubmit={updateClient}
            onCancel={cancelEditClient}
            data-testid="profile-edit-page"
          />
        </Route>
        <Route path={path + AppRoute.emailTypes}>
          <EmailTypes client={client} />
        </Route>
      </Switch>
    </>
  );
};

export default ProfileEditPage;
