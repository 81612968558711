import { Box } from "@chakra-ui/react";

import Popover, { PopoverProps } from "components/partials/popover/popover";

interface FilterSelectPopoverProps extends PopoverProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FilterSelectPopover = ({
  children,
  onOpen,
  onClose,
  triggerElement,
  isOpen,
  setIsOpen,
  ...rest
}: FilterSelectPopoverProps) => {
  return (
    <Popover
      padding={4}
      isOpen={isOpen}
      popoverWidth="inherit"
      triggerElement={triggerElement}
      onOpen={onOpen}
      onClose={onClose}
      arrow={false}
      {...rest}>
      <Box width="100%" maxHeight={278} overflowY="auto">
        {children}
      </Box>
    </Popover>
  );
};
