import React, { createContext, SetStateAction, useEffect, useState } from "react";

import { env } from "env";

import toast from "components/partials/toast/toast";

import { getErrorMessage, sortByProperty } from "utilities";
import { hasPermission } from "utilities/user";

import { useCurrentUser } from "state/ducks";

import { Permission } from "types/auth";
import {
  CommentsSummary,
  CommentFilterOption,
  CommentReply,
  commentFilterOptions,
  NewComment,
  Comment,
  emptyCommentSummary,
} from "types/comments";
import {
  DEFAULT_PAGINATED_REQUEST_OPTIONS,
  emptyPaginatedContentResponse,
  PaginatedContentResponse,
  PaginatedRequestOptions,
} from "types/pagination";

export interface CommentContextState {
  commentData: PaginatedContentResponse<Comment>;
  setCommentData: React.Dispatch<SetStateAction<PaginatedContentResponse<Comment>>>;
  commentsSummary: CommentsSummary;
  setCommentsSummary: React.Dispatch<SetStateAction<CommentsSummary>>;
  commentFilter: CommentFilterOption;
  setCommentFilter: React.Dispatch<SetStateAction<CommentFilterOption>>;
  loadComments: (options?: PaginatedRequestOptions) => Promise<PaginatedContentResponse<Comment>>;
  loadCommentsSummary: () => Promise<CommentsSummary>;
  submitNewComment: (comment: NewComment) => Promise<Comment | void>;
  updateComment: (comment: Comment) => Promise<Comment | void>;
  loadCommentReplies: (comment: Comment) => Promise<PaginatedContentResponse<CommentReply>>;
  submitNewCommentReply: (newComment: NewComment) => Promise<CommentReply | void>;
  lastActiveCommentId: string | undefined;
  setLastActiveCommentId: React.Dispatch<SetStateAction<string | undefined>>;
  isFABOpen: boolean;
  setIsFABOpen: React.Dispatch<SetStateAction<boolean>>;
  openCommentAnnotationId: string | undefined;
  setOpenCommentAnnotationId: (id: string | undefined) => void;
  newCommentModuleId: string | undefined;
  setNewCommentModuleId: (id: string | undefined) => void;
  isClientUser: boolean;
}
const initialContextState: CommentContextState = {
  commentData: emptyPaginatedContentResponse,
  setCommentData: () => {},
  commentsSummary: emptyCommentSummary,
  setCommentsSummary: () => {},
  commentFilter: commentFilterOptions[0],
  setCommentFilter: () => {},
  loadComments: () => Promise.reject("function called before instantiated in context"),
  loadCommentsSummary: () => Promise.reject("function called before instantiated in context"),
  submitNewComment: () => Promise.reject("function called before instantiated in context"),
  updateComment: () => Promise.reject("function called before instantiated in context"),
  loadCommentReplies: () => Promise.reject("function called before instantiated in context"),
  submitNewCommentReply: () => Promise.reject("function called before instantiated in context"),
  lastActiveCommentId: undefined,
  setLastActiveCommentId: () => {},
  isFABOpen: false,
  setIsFABOpen: () => {},
  openCommentAnnotationId: undefined,
  setOpenCommentAnnotationId: () => {},
  newCommentModuleId: undefined,
  setNewCommentModuleId: () => {},
  isClientUser: false,
};

const CommentContext: React.Context<CommentContextState> = createContext(initialContextState);
export default CommentContext;

interface CommentContextProps {
  loadComments: (options?: PaginatedRequestOptions) => Promise<PaginatedContentResponse<Comment>>;
  loadCommentsSummary: () => Promise<CommentsSummary>;
  submitNewComment: (comment: NewComment) => Promise<Comment>;
  updateComment: (comment: Comment) => Promise<Comment>;
  loadCommentReplies: (comment: Comment) => Promise<PaginatedContentResponse<CommentReply>>;
  submitNewCommentReply: (newComment: NewComment) => Promise<CommentReply>;
  children?: React.ReactNode;
}

export const CommentContextProvider = ({
  loadComments,
  loadCommentsSummary,
  submitNewComment,
  updateComment,
  loadCommentReplies,
  submitNewCommentReply,
  children,
}: CommentContextProps) => {
  const [commentData, setCommentData] = useState<PaginatedContentResponse<Comment>>(
    emptyPaginatedContentResponse
  );
  const [commentsSummary, setCommentsSummary] = useState<CommentsSummary>({
    actionItemCount: 0,
    commentCount: 0,
    hiddenCount: 0,
    openCount: 0,
  });
  const [commentFilter, setCommentFilter] = useState<CommentFilterOption>(commentFilterOptions[0]);
  const [lastActiveCommentId, setLastActiveCommentId] = useState<string>();
  // Each commentContext should have one FAB at a time
  const [isFABOpen, setIsFABOpen] = useState<boolean>(false);
  const [openCommentAnnotationId, setOpenCommentAnnotationId] = useState<string | undefined>();
  const [newCommentModuleId, setNewCommentModuleId] = useState<string | undefined>();
  const currentUser = useCurrentUser();
  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);

  useEffect(() => {
    handleLoadComments();
    handleLoadCommentsSummary();
    // eslint-disable-next-line
  }, [commentFilter]);

  function appendBadgeNumber(comment: Comment, currentCount = 0) {
    if (comment.annotationId) {
      comment.badgeNumber = (currentCount + 1).toString();
    }
    return comment;
  }

  function appendBadgeNumbers(comments: Comment[]) {
    return comments.map((comment, currentCount) => appendBadgeNumber(comment, currentCount));
  }

  const handleLoadComments = (options?: PaginatedRequestOptions) => {
    return loadComments({
      ...DEFAULT_PAGINATED_REQUEST_OPTIONS,
      ...options,
      filter: commentFilter.value,
    }).then((response) => {
      const commentsByDate = sortByProperty(response?.content, "createdDate") as Comment[];
      const comments: PaginatedContentResponse<Comment> = {
        ...response,
        content: appendBadgeNumbers(commentsByDate),
      };
      setCommentData(comments);
      return comments;
    });
  };

  const handleLoadCommentsSummary = () => {
    return loadCommentsSummary().then((res) => {
      setCommentsSummary(res);
      return res;
    });
  };

  const handleSubmitNewComment = (comment: NewComment) => {
    return submitNewComment(comment)
      .then((newComment) => {
        setCommentData((comments) => {
          return {
            ...comments,
            content: [
              appendBadgeNumber(newComment, commentData.content.length),
              ...comments.content,
            ],
          };
        });
        setLastActiveCommentId(newComment.id);
        handleLoadCommentsSummary();
        return newComment;
      })
      .catch((err) => {
        toast.error({
          title: "Failed to post comment",
          message: getErrorMessage(err),
        });
      });
  };

  /**
   * Append a reply to an existing comment */
  const handleUpdateComment = (comment: Comment) => {
    return updateComment(comment)
      .then((updatedComment) => {
        let newCommentData = [...commentData.content];
        const foundIndex = newCommentData.findIndex((c) => c.id === updatedComment.id);

        // Updated comment retains original badge number
        updatedComment.badgeNumber = comment.badgeNumber;

        if (foundIndex >= 0) {
          newCommentData[foundIndex] = updatedComment;
          setCommentData((prevData) => {
            return { ...prevData, content: newCommentData };
          });
        } else {
          setCommentData((prevData) => {
            return { ...prevData, content: [...newCommentData, updatedComment] };
          });
        }
        setLastActiveCommentId(updatedComment.id);
        handleLoadCommentsSummary();
        return updatedComment;
      })
      .catch((err) => {
        toast.error({
          title: "Failed to update comment",
          message: getErrorMessage(err),
        });
      });
  };

  const handleSubmitNewCommentReply = (newComment: NewComment) => {
    return submitNewCommentReply(newComment)
      .then((res) => {
        let newCommentData = [...commentData.content];
        newCommentData.forEach((c, i) => {
          if (c.id === newComment.parentId) {
            newCommentData[i].replyCount++;
          }
        });
        setCommentData((prevData) => {
          return { ...prevData, content: newCommentData };
        });
        setLastActiveCommentId(newComment.parentId);
        return res;
      })
      .catch((err) => {
        toast.error({
          title: "Failed to submit reply",
          message: getErrorMessage(err),
        });
      });
  };

  const handleLoadCommentReplies = (comment: Comment) => {
    setLastActiveCommentId(comment.id);
    return loadCommentReplies(comment).then((res) => {
      return res;
    });
  };

  const CommentContextState: CommentContextState = {
    commentData,
    setCommentData,
    commentsSummary,
    setCommentsSummary,
    commentFilter,
    setCommentFilter,
    lastActiveCommentId,
    setLastActiveCommentId,
    isFABOpen,
    setIsFABOpen,
    newCommentModuleId,
    setNewCommentModuleId,
    openCommentAnnotationId,
    loadComments: handleLoadComments,
    loadCommentsSummary: handleLoadCommentsSummary,
    submitNewComment: handleSubmitNewComment,
    updateComment: handleUpdateComment,
    loadCommentReplies: handleLoadCommentReplies,
    submitNewCommentReply: handleSubmitNewCommentReply,
    setOpenCommentAnnotationId,
    isClientUser,
  };

  if (env.NODE_ENV === "development") {
    (window as any).CommentContextState = CommentContextState;
  }

  return <CommentContext.Provider value={CommentContextState}>{children}</CommentContext.Provider>;
};
