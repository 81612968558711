import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { HStack } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";

import { RouteMatchTypes } from "containers/admin/clients/campaign/campaign-settings-page/campaign-settings-page";
import EmailForm from "containers/admin/clients/touchpoint/components/email-form/email-form";
import { H2, H5 } from "components/partials/typography/typography";
import PageContentHeader from "components/partials/page-content-header/page-content-header";
import Breadcrumbs from "components/new/beadcrumbs/breadcrumbs";
import toast from "components/partials/toast/toast";
import { useCurrentClient } from "state/ducks/clients";
import { useCurrentCampaign, setCurrentCampaign } from "state/ducks/campaigns";
import { setCurrentTouchpoint, useCurrentTouchpoint } from "state/ducks";
import Touchpoint, { TouchpointAttributes } from "models/touchpoint";

import { getErrorMessage, transformStringNullToValueNull } from "utilities";
import { Route as AppRoute } from "utilities/app-routes";

import { usePagePermission } from "hooks/use-page-permission";
import { usePermissionBlacklist } from "hooks/use-permission-blacklist";

import { Permission } from "types/auth";

const EmailEditPage = ({
  creativeIndexUrl,
  baseCreativeUrl,
}: {
  creativeIndexUrl: string;
  baseCreativeUrl: string;
}) => {
  const history = useHistory();
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const { params }: RouteMatchTypes = useRouteMatch();
  const currentTouchpoint = useCurrentTouchpoint();
  const dispatch = useDispatch();

  usePagePermission(Permission.PERM_TOUCHPOINT_READ);
  usePermissionBlacklist([Permission.PERM_CLIENT_USER]);

  const updateEmail = (attributes: TouchpointAttributes) => {
    const metaData = {
      ...currentTouchpoint.attributes,
      contentInteraction: transformStringNullToValueNull(attributes.contentInteraction),
      contextAcademics: attributes.contextAcademics,
      contextApply: attributes.contextApply,
      contextCampusLife: attributes.contextCampusLife,
      contextEducationOutcomes: attributes.contextEducationOutcomes,
      contextFinancialAid: attributes.contextFinancialAid,
      contextVisit: attributes.contextVisit,
      contextDeposit: attributes.contextDeposit,
      contextNA: attributes.contextNA,
      contentType: transformStringNullToValueNull(attributes.contentType),
      description: attributes.description,
      name: attributes.name,
      campaignId: attributes.campaignId,
    };

    Touchpoint.replace({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      data: metaData,
      id: attributes.id,
    })
      .then((updatedTouchpoint) => {
        dispatch(setCurrentTouchpoint(updatedTouchpoint.attributes));

        let redirectUrl = baseCreativeUrl;

        if (currentCampaign.id !== attributes.campaignId) {
          const updatedCampaign = { ...currentCampaign.attributes, id: attributes.campaignId };
          dispatch(setCurrentCampaign(updatedCampaign));
          redirectUrl = redirectUrl.replace(currentCampaign.id, attributes.campaignId);
        }
        toast.success({
          title: "Email updated",
        });

        history.push(redirectUrl + AppRoute.details);
      })
      .catch((err) => {
        toast.error({
          title: "Failed to edit Email",
          message: getErrorMessage(err?.response?.data),
        });
      });
  };

  const cancelEditEmail = () => {
    history.push(baseCreativeUrl + AppRoute.details);
  };

  return (
    <Box>
      <Breadcrumbs
        breadcrumbs={[
          { label: currentClient?.name, href: `/clients/${params?.clientId}/profile` },
          {
            label: currentCampaign?.name,
            href: `/clients/${params?.clientId}/campaigns/${params?.campaignId}/creative`,
          },
          {
            label: currentTouchpoint?.name || "Creative name",
            href: `/clients/${params?.clientId}/campaigns/${params?.campaignId}/creative/${params?.touchpointId}/preview`,
          },
        ]}
      />
      <PageContentHeader>
        <HStack className="pt-4 space-x-3" align="baseline">
          <>
            <H2>Edit Email</H2>
            <H5>{currentTouchpoint?.name}</H5>
          </>
        </HStack>
      </PageContentHeader>
      <EmailForm
        email={currentTouchpoint}
        onSubmit={updateEmail}
        onCancel={cancelEditEmail}
        baseCreativeUrl={baseCreativeUrl}
        creativeIndexUrl={creativeIndexUrl}
        data-testid="email-edit-details-page"
      />
    </Box>
  );
};

export default EmailEditPage;
