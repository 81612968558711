import { Redirect, Route, Switch } from "react-router-dom";
import * as FullStory from "@fullstory/browser";
// Admin
import ClientsRoutes from "containers/admin/clients/clients-routes";
import InboxPage from "containers/admin/inbox/inbox";
// Client
import ReviewsPage from "containers/client/reviews/reviews";
// Common
import UIKitPage from "containers/ui-kit-page/ui-kit-page";
import NotFoundPage from "containers/not-found-page/not-found-page";

import AppHeader from "components/partials/header/header";
import Logout from "components/partials/logout/logout";

import { useCurrentUser } from "state/ducks";

import useSetPageTitle from "hooks/use-set-page-title";
import { hasPermission } from "utilities/user";
import { Route as AppRoute } from "utilities/app-routes";

import { Permission } from "types/auth";

// TODO: All the routes need to be here NOT through out the app in different modules
const AuthenticatedApp = () => {
  const currentUser = useCurrentUser();

  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);

  const baseRouteBasedOnPermission = isClientUser ? AppRoute.reviews : AppRoute.clients;

  // Set site title from Two Ocean to Apollo after login, if !isClientUser.
  // TODO: Append page title, like useSetPageTitle({ isClientUser, pageTitle: "Inbox" })
  useSetPageTitle({ isClientUser });

  // Associate user mail with FullStory session
  FullStory.identify(currentUser.id, {
    email: currentUser.email,
  });

  return (
    <>
      <AppHeader />
      <Switch>
        <Redirect exact from={AppRoute.login} to={baseRouteBasedOnPermission} />
        <Redirect exact from="/" to={baseRouteBasedOnPermission} />
        {isClientUser && <Redirect exact from="/clients" to={AppRoute.reviews} />}
        {/*Admin*/}
        <Route path={AppRoute.clients} component={ClientsRoutes} />
        <Route path={AppRoute.inbox} component={InboxPage} />
        {/*Client*/}
        <Route path={AppRoute.reviews} component={ReviewsPage} />
        <Route path={AppRoute.approved} component={ReviewsPage} />
        {/*Common*/}
        <Route exact path={AppRoute.login} component={Logout} />
        <Route exact path={AppRoute.logout} component={Logout} />
        <Route path={AppRoute.uiKit} component={UIKitPage} />
        {/* 404 route breaks sub-navigation... */}
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default AuthenticatedApp;
