import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";

import TouchpointVersionRoutes from "containers/admin/clients/touchpoint/tab-components/version-history-tab/version-history-routes";

import { NoVersionFound } from "containers/admin/clients/touchpoint/components/no-version-found/no-version-found";
import { onSubmitFormProps } from "components/modals/version-edit-modal/version-edit-modal";
import TouchpointVersionTable from "containers/admin/clients/touchpoint/tab-components/version-history-tab/components/touchpoint-version-table/touchpoint-version-table";
import { GeneralCommentsFAB } from "components/partials/general-comments-fab/general-comments-fab";
import toast from "components/partials/toast/toast";

import { useCurrentCampaign, useCurrentClient, useCurrentTouchpoint } from "state/ducks";
import { setTouchpointVersions, touchpointVersionsSelector } from "state/ducks/touchpoint";
import TouchpointVersion from "models/touchpoint-version";

import { getErrorMessage } from "utilities";
import { BEE_SIDEBAR_WIDTH_LG, BEE_SIDEBAR_WIDTH_SM } from "utilities/constants";

import ApolloTheme from "themes/apollo";

interface TouchpointVersionTabProps {
  baseCreativeUrl: string;
}

const FABSpaceRight: string = ApolloTheme.sizes[7];
const FABSpaceBottom: string = ApolloTheme.sizes[4];

const VersionHistoryTab = ({ baseCreativeUrl }: TouchpointVersionTabProps) => {
  const currentCampaign = useCurrentCampaign();
  const currentClient = useCurrentClient();
  const currentTouchpoint = useCurrentTouchpoint();
  const createFirstDraftModal = useDisclosure();
  const [showTable, setShowTable] = useState<boolean>();
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const versions = useSelector(touchpointVersionsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (versions.items.length >= 1) {
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  }, [versions]);

  const loadTouchpointVersions = () => {
    TouchpointVersion.all({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      touchpointId: currentTouchpoint.id,
      options: { page: 0 },
    }).then((response) => {
      const resultsAttributes = response.items.map((v) => v.attributes);
      dispatch(setTouchpointVersions({ ...response, items: resultsAttributes }));
    });
  };

  const createDraftSubmit = ({ version, versionNotes }: onSubmitFormProps) => {
    TouchpointVersion.create({
      campaignId: currentCampaign.id,
      clientId: currentClient.id,
      touchpointId: currentTouchpoint.id,
      version: version,
      versionNotes: versionNotes,
    })
      .then(() => {
        toast.success({
          title: "Draft Created",
        });
        loadTouchpointVersions();
      })
      .catch((err) => {
        toast.error({
          title: "Failed to create draft",
          message: getErrorMessage(err?.response?.data),
        });
        loadTouchpointVersions();
      })
      .finally(() => {
        createFirstDraftModal.onClose();
      });
  };

  const touchpointType = currentTouchpoint.type;

  if (showTable === false) {
    return (
      <NoVersionFound
        touchpointType={touchpointType}
        baseCreativeUrl={baseCreativeUrl}
        onCreateDraft={createDraftSubmit}
      />
    );
  }

  const partsOfUrl = location.pathname.split("/");
  const isDesignTab = partsOfUrl[partsOfUrl.length - 1] === "design";

  return (
    <Switch>
      <Route path={`${path}/:builderId`}>
        <TouchpointVersionRoutes parentUrl={url} />
        <GeneralCommentsFAB
          right={
            isDesignTab
              ? {
                  base: `calc(${BEE_SIDEBAR_WIDTH_SM} + ${FABSpaceRight})`,
                  beeLarge: `calc(${BEE_SIDEBAR_WIDTH_LG} + ${FABSpaceBottom})`,
                }
              : undefined
          }
          id="fab"
        />
      </Route>
      <Route>
        <TouchpointVersionTable
          baseCreativeUrl={baseCreativeUrl}
          touchpointType={touchpointType}
          creativeName={currentTouchpoint.name}
        />
      </Route>
    </Switch>
  );
};

export default VersionHistoryTab;
