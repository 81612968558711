import PaginatedTable from "components/partials/paginated-table/paginated-table";
import RouterLink from "components/partials/router-link/router-link";
import { Span } from "components/partials/typography/typography";
import { UserBadge } from "components/partials/user-badge/user-badge";
import { StatusDisplay } from "components/partials/status-display/status-display";

import { useCurrentClient } from "state/ducks/clients";
import { useCurrentCampaign } from "state/ducks/campaigns";
import Touchpoint from "models/touchpoint";

import { sortAlphabetically } from "utilities";

import { PaginatedRequestOptions, PaginatedResponse } from "types/pagination";
import { TouchpointType, TouchpointTypeLabel } from "types/touchpoint";
import { TableRow } from "components/table/table";
import { TableCell } from "components/table/table-cell/table-cell";

const LandingPageTable = ({ creativeIndexUrl }: { creativeIndexUrl: string }) => {
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();

  const loadAllLandingPages = (
    options: PaginatedRequestOptions
  ): Promise<PaginatedResponse<Touchpoint>> => {
    return Touchpoint.all({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      type: TouchpointType.LANDING_PAGE,
      options: { ...options, sort: "name" },
    }).then((results) => {
      results.items = sortAlphabetically(results.items);
      return results;
    });
  };

  return (
    <PaginatedTable
      headers={[
        `${TouchpointTypeLabel.LP_TOUCHPOINT} name`,
        "URL",
        "Assignee",
        "Workflow status",
        "Priority",
      ]}
      fetchPage={loadAllLandingPages}>
      {(touchpoint: Touchpoint) => {
        return (
          <TableRow>
            <TableCell>
              <RouterLink
                data-testid={`lptable-name-${touchpoint.id}`}
                to={`${creativeIndexUrl}/${touchpoint.id}`}>
                {touchpoint.name}
              </RouterLink>
            </TableCell>
            <TableCell>
              <Span>{touchpoint.url}</Span>
            </TableCell>
            {/* below we display the assigned user name and avatar or a stand-in if none is selected */}
            <TableCell>
              {touchpoint.assignee && (
                <UserBadge
                  id={touchpoint.assignee.id}
                  firstName={touchpoint.assignee.firstName}
                  lastName={touchpoint.assignee.lastName}
                  imageUrl={touchpoint.assignee.imageUrl}
                />
              )}
            </TableCell>
            <TableCell>
              {touchpoint.workflowStatus && (
                <StatusDisplay
                  status={touchpoint.workflowStatus}
                  liveText={"Published"}
                  archivedText={"Archived"}
                  draftText={"Draft"}
                />
              )}
            </TableCell>
            <TableCell>{touchpoint.priority && `P${touchpoint.priority}`}</TableCell>
          </TableRow>
        );
      }}
    </PaginatedTable>
  );
};

export default LandingPageTable;
