import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Flex, useDisclosure } from "@chakra-ui/react";

import Button from "components/forms/button/button";
import Input from "components/forms/input/input";

import Textarea from "components/forms/textarea/textarea";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";
import ErrorAlert from "components/partials/error-alert/error-alert";
import Form from "components/forms/form/form";
import toast from "components/partials/toast/toast";

import { useCurrentClient } from "state/ducks/clients";
import { setCurrentTheme } from "state/ducks/themes";
import { useCurrentUser } from "state/ducks";
import Theme from "models/theme";

import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";
import { ContentStatus } from "types";

interface ThemeDetailsProps {
  theme?: Theme;
  localValues: {
    name: string;
    description: string;
    status: ContentStatus;
  };
  setLocalValues: React.Dispatch<
    React.SetStateAction<{
      name: string;
      description: string;
      status: ContentStatus;
    }>
  >;
}

const ThemeDetails = ({ theme, localValues, setLocalValues }: ThemeDetailsProps) => {
  const client = useCurrentClient();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const [themeCreateOrUpdateError, setThemeCreateOrUpdateError] = useState<string>();
  const [themeDeleteError, setThemeDeleteError] = useState<string>();

  const updateTheme = (updatedTheme: Theme) => {
    dispatch(setCurrentTheme(updatedTheme.attributes));
  };

  const confirmDeleteModal = useDisclosure();
  const confirmCancelModal = useDisclosure();

  function returnToThemeConfigPage() {
    history.push(`/clients/${client.id}/themes`);
  }

  const clearErrors = () => {
    setThemeDeleteError(undefined);
    setThemeCreateOrUpdateError(undefined);
  };

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    clearErrors();

    if (!theme) {
      Theme.create({ ...localValues, clientId: client.id })
        .then((newTheme) => {
          updateTheme(newTheme);
          toast.success({
            title: "Theme created",
          });
          history.replace({
            pathname: `/clients/${client.id}/themes/${newTheme.id}/edit`,
          });
        })
        .catch((response) => {
          setThemeCreateOrUpdateError(JSON.stringify(response.message));
        });
    } else {
      Theme.update({ attributes: { ...localValues }, id: theme.id, clientId: client.id })
        .then((updatedTheme) => {
          updateTheme(updatedTheme);
          toast.success({
            title: "Theme saved",
          });
        })
        .catch((response) => {
          setThemeCreateOrUpdateError(JSON.stringify(response.message));
        });
    }
  }

  const handleChange = useMemo(() => {
    return (name: "name" | "description" | "status") =>
      (
        event:
          | React.ChangeEvent<HTMLInputElement>
          | React.ChangeEvent<HTMLSelectElement>
          | React.ChangeEvent<HTMLTextAreaElement>
      ) =>
        setLocalValues((prev) => ({ ...prev, [name]: event.target.value }));
  }, [setLocalValues]);

  function handleDeleteButton() {
    clearErrors();
    confirmDeleteModal.onOpen();
  }

  function deleteTheme() {
    clearErrors();
    if (theme?.id) {
      Theme.delete({ clientId: client.id, id: theme.id })
        .then(() => {
          const pathname = `/clients/${client.id}/themes`;
          toast.success({
            title: "Theme Deleted",
          });
          history.replace({
            pathname,
          });
        })
        .catch((response) => {
          setThemeDeleteError(JSON.stringify(response.message) || "Failed to delete theme");
        });
    }
  }

  const canEditFields = hasPermission(currentUser, Permission.PERM_THEME_WRITE);

  return (
    <Form data-testid="theme-details" onSubmit={handleSubmit}>
      <Input
        label="Theme name"
        value={localValues.name}
        onChange={handleChange("name")}
        data-testid="theme-name-input"
        isDisabled={!canEditFields}
      />
      <Textarea
        label="Theme description (optional)"
        value={localValues.description}
        onChange={handleChange("description")}
        data-testid="theme-description-input"
        isDisabled={!canEditFields}
        characterLimit={1500}
      />
      {theme && (
        <Input
          label="Theme Status"
          value={theme.status}
          data-testid="theme-status-text"
          isDisabled={true}
        />
      )}

      {/* Form Footer - Save, Close, Delete buttons */}
      <Flex justifyContent="space-between">
        <Box>
          <Button
            type="submit"
            data-testid="save-close-button"
            isDisabled={!canEditFields || !localValues.name}>
            Save changes
          </Button>

          <Button variant="link" onClick={confirmCancelModal.onOpen} data-testid="cancel-button">
            Close
          </Button>

          {themeCreateOrUpdateError && (
            <ErrorAlert error={themeCreateOrUpdateError} data-testid="theme-create-update-error" />
          )}
        </Box>
        {theme?.status === ContentStatus.DRAFT && (
          <Button
            variant="link"
            leftIcon={<DeleteIcon fontSize="large" />}
            onClick={handleDeleteButton}
            data-testid="delete-theme-button"
            isDisabled={!canEditFields}>
            Delete Theme
          </Button>
        )}
        <>
          <ConfirmationModal
            {...confirmDeleteModal}
            message="Are you sure you want to delete this theme?"
            confirmButtonText="Delete Theme"
            onConfirm={deleteTheme}
            error={themeDeleteError}
            modalType="danger"
          />

          <ConfirmationModal
            {...confirmCancelModal}
            message="Are you sure you want to exit? All unsaved changes will be lost"
            cancelButtonText="No"
            confirmButtonText="Yes"
            onConfirm={returnToThemeConfigPage}
            modalType="warning"
          />
        </>
      </Flex>
    </Form>
  );
};

export default ThemeDetails;
