import {
  Box,
  ButtonGroup,
  ButtonGroupProps,
  useRadio,
  useRadioGroup,
  useTheme,
} from "@chakra-ui/react";

/* Usage example:
<ToggleButtonGroup
  groupName="theme-info"
  options={["Details", "Usage", "Version History"]}
  onChange={(option) => setShowContentFor(option)}
  mb={8}
/> */

export interface ToggleButtonsProps extends ButtonGroupProps {
  groupName: string;
  options: string[];
  defaultValue?: string;
  onChange: (e?: any) => void;
  value?: string;
}

const ToggleButtonGroup = ({
  variant = "secondary",
  value,
  groupName,
  options,
  defaultValue = options[0],
  onChange,
  ...rest
}: ToggleButtonsProps) => {
  const theme = useTheme();

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: groupName,
    defaultValue: defaultValue,
    onChange: onChange,
    value: value,
  });
  const group = getRootProps();
  const radius = theme.radii.lg;
  const isPrimary = variant === "primary";

  const getBorderRadius = ({
    firstChild,
    lastChild,
  }: {
    firstChild: boolean;
    lastChild: boolean;
  }) => {
    return firstChild
      ? `${radius} 0 0 ${radius}` // top-left and bottom-left
      : lastChild
      ? `0 ${radius} ${radius} 0` // top-right and bottom-right
      : "0";
  };

  return (
    <ButtonGroup
      data-testid="toggle-button-group"
      boxShadow={!isPrimary ? "xs" : "none"}
      isAttached
      {...group}
      {...rest}>
      {options.map((value, index) => {
        const radio = getRadioProps({ value });
        const firstChild = index === 0;
        const lastChild = index === options.length - 1;

        return (
          <ToggleButton
            key={value}
            testid={`toggle-button-${index + 1}`}
            isPrimary={isPrimary}
            borderRadius={
              isPrimary ? `${radius} ${radius} 0 0` : getBorderRadius({ firstChild, lastChild })
            }
            {...radio}>
            {value}
          </ToggleButton>
        );
      })}
    </ButtonGroup>
  );
};

export default ToggleButtonGroup;

const ToggleButton = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const theme = useTheme();
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box
      as="label"
      data-testid={`toggle-button-${props.testid}`}
      _notLast={{ borderRight: `1px solid ${theme.colors.gray[200]}` }}>
      <input {...input} />
      <Box
        {...checkbox}
        fontFamily="body"
        fontSize="md"
        fontWeight="bold"
        lineHeight="tall"
        cursor="pointer"
        border={props.isPrimary ? "none" : "1px solid white"}
        borderRadius={props.borderRadius}
        bg={props.isPrimary ? "transparent" : "white"}
        color="text.link"
        _checked={{
          bg: props.isPrimary ? "gray.100" : "primary",
          color: props.isPrimary ? "primary" : "white",
          borderColor: props.isPrimary ? "transparent" : "primary",
        }}
        px={4}
        py={2}>
        {props.children}
      </Box>
    </Box>
  );
};
