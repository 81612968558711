import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { currentThemeSelector, setCurrentTheme } from "state/ducks/themes";
import { useCurrentClient } from "state/ducks/clients";
import Theme from "models/theme";

import { Route } from "utilities/app-routes";

const ThemeProvider: React.FC = ({ children }) => {
  const client = useCurrentClient();
  const { themeId } = useParams<{ themeId: string }>();
  const history = useHistory();
  const theme = useSelector(currentThemeSelector);
  const dispatch = useDispatch();

  useEffect(
    function mountTheme() {
      if (themeId && theme?.id !== themeId) {
        Theme.find({ clientId: client.id, id: themeId })
          .then((newTheme) => dispatch(setCurrentTheme(newTheme.attributes)))
          .catch(() => history.push(Route.notFound));
      }
    },
    [client, themeId, history, dispatch, theme]
  );

  useEffect(() => {
    return () => {
      dispatch(setCurrentTheme(null));
    };
    // eslint-disable-next-line
  }, []);

  return <>{theme && theme.id === themeId ? children : null}</>;
};

export default ThemeProvider;
