import React from "react";
import ReactSelect, {
  components as selectComponents,
  GroupTypeBase,
  OptionTypeBase,
  Props as SelectProps,
  SelectComponentsConfig,
  StylesConfig,
  Theme,
} from "react-select";
import {
  chakra,
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  Divider,
  Portal,
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
  useTheme,
  Icon,
  RecursiveCSSObject,
  CSSWithMultiValues,
  FormControl,
  Box,
} from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";

import Label from "components/forms/label/label";
import HelperText from "components/forms/helper-text/helper-text";
import ErrorMessage from "components/forms/error-message/error-message";

import { LabelValuePair } from "types";

interface ItemProps extends CSSWithMultiValues {
  _disabled: CSSWithMultiValues;
  _focus: CSSWithMultiValues;
}

const chakraStyles: SelectProps["styles"] = {
  input: (provided) => ({
    ...provided,
    color: "inherit",
    lineHeight: 1,
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: "",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0.125rem 1rem",
  }),
};

const chakraComponents: SelectProps["components"] = {
  // Control components
  Control: ({ children, innerRef, innerProps, isDisabled, isFocused }) => {
    const inputStyles = useMultiStyleConfig("Input", {});
    return (
      <StylesProvider value={inputStyles}>
        <Flex
          ref={innerRef}
          sx={{
            ...inputStyles.field,
            h: "auto",
            minHeight: "2.5rem",
            p: 0,
            overflow: "hidden",
            backgroundColor: "white",
            paddingY: "5px",
          }}
          {...innerProps}
          {...(isFocused && { "data-focus": true })}
          {...(isDisabled && { disabled: true })}>
          {children}
        </Flex>
      </StylesProvider>
    );
  },
  MultiValueContainer: ({ children, innerRef, size = "sm", data: { isFixed } }) => (
    <Tag
      ref={innerRef}
      sx={{
        mx: 1,
        boxShadow: "3d",
        background: "offWhite",
        borderRadius: "lg",
        fontFamily: "body",
        fontSize: size,
      }}
      fontSize={size === "sm" ? "sm" : "md"}
      variant={isFixed ? "solid" : "subtle"}>
      {children}
    </Tag>
  ),
  MultiValueLabel: ({ children, innerRef, innerProps }) => (
    <TagLabel ref={innerRef} {...innerProps}>
      {children}
    </TagLabel>
  ),
  MultiValueRemove: ({ children, innerRef, innerProps, data: { isFixed } }) => {
    if (isFixed) {
      return null;
    }

    return (
      <TagCloseButton
        data-testid="multiSelect-pill-close"
        ref={innerRef}
        sx={{
          color: "highlight.200",
          opacity: "1",
          "&:hover": {
            backgroundColor: "#dadada !important",
            color: "#090d09 !important",
          },
        }}
        {...innerProps}>
        {children}
      </TagCloseButton>
    );
  },
  IndicatorSeparator: ({ innerProps }) => (
    <Divider {...innerProps} orientation="vertical" opacity="0" />
  ),
  ClearIndicator: ({ innerProps }) => null,
  DropdownIndicator: () => {
    return (
      <Icon
        as={FiChevronDown}
        h={4}
        w={5}
        bg="white"
        mx={2}
        data-testid="multiSelect-dropdown-arrow"
      />
    );
  },
  // Menu components
  MenuPortal: ({ children }) => <Portal>{children}</Portal>,
  Menu: ({ children, ...props }) => {
    const menuStyles = useMultiStyleConfig("Menu", {});
    return (
      <selectComponents.Menu {...props}>
        <StylesProvider value={menuStyles}>{children}</StylesProvider>
      </selectComponents.Menu>
    );
  },
  MenuList: ({ innerRef, children, maxHeight }) => {
    const { list } = useStyles();
    return (
      <chakra.div
        sx={{
          ...list,
          maxH: `${maxHeight}px`,
          overflowY: "auto",
          backgroundColor: "white",
        }}
        fontSize="sm"
        ref={innerRef}>
        {children}
      </chakra.div>
    );
  },
  GroupHeading: ({ innerProps, children }) => {
    const { groupTitle } = useStyles();
    return (
      <chakra.div sx={groupTitle} {...innerProps}>
        {children}
      </chakra.div>
    );
  },
  Option: ({ innerRef, innerProps, children, isFocused, isDisabled }) => {
    const { item } = useStyles();
    return (
      <chakra.div
        role="button"
        sx={{
          ...item,
          w: "100%",
          textAlign: "left",
          bg: isFocused ? (item as RecursiveCSSObject<ItemProps>)._focus.bg : "transparent",
          ...(isDisabled && (item as RecursiveCSSObject<ItemProps>)._disabled),
        }}
        ref={innerRef}
        {...innerProps}
        {...(isDisabled && { disabled: true })}>
        {children}
      </chakra.div>
    );
  },
};

//provide an array of objects like below in an 'options' prop to get your values in the MultiSelect
// const myMultiSelectOptions = [
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" },
//   { value: "academics", label: "Academics" },
// ];
export function MultiSelect<
  OptionType extends OptionTypeBase = LabelValuePair,
  IsMulti extends boolean = true,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
  name = "",
  styles = {},
  components = {},
  theme,
  id,
  label,
  size,
  placeholder = "",
  helperText,
  errorMessage,
  isDisabled = false,
  ...props
}: SelectProps<OptionType, IsMulti, GroupType>) {
  const chakraTheme = useTheme();
  const placeholderColor = chakraTheme.colors.gray[400];

  return (
    <FormControl isInvalid={!!errorMessage} isDisabled={isDisabled} id={id}>
      {label && <Label value={label} fontWeight="bold" />}
      <Box data-testid={props["data-testid"]}>
        <ReactSelect
          id={id}
          name={name}
          isDisabled={isDisabled}
          placeholder={placeholder}
          //@ts-ignore
          isMulti
          components={{
            ...(chakraComponents as SelectComponentsConfig<OptionType, IsMulti, GroupType>),
            ...components,
          }}
          styles={{
            ...(chakraStyles as StylesConfig<OptionType, IsMulti, GroupType>),
            ...styles,
          }}
          theme={(baseTheme) => ({
            ...baseTheme,
            borderRadius: chakraTheme.radii.md,
            colors: {
              ...baseTheme.colors,
              neutral50: placeholderColor, // placeholder text color
              neutral40: placeholderColor, // noOptionsMessage color
              ...(theme as Theme)?.colors,
            },
            spacing: {
              ...baseTheme.spacing,
              ...(theme as Theme)?.spacing,
            },
          })}
          {...props}
        />
      </Box>
      {helperText && <HelperText message={helperText} />}
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </FormControl>
  );
}

export default MultiSelect;
