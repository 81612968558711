import { Box, FormControl, Stack, BoxProps } from "@chakra-ui/react";

import FormGroup from "components/forms/form-group/form-group";
import Input, { TextInputProps } from "components/forms/input/input";
import Select from "components/forms/select/select";
import { H5, P, Span } from "components/partials/typography/typography";

import { capitalize } from "utilities";
import { getMultiSelectInitialValuesFromString } from "utilities/index";
import { parseTypekitDisplayNameFromCssStack, WEB_SAFE_FONTS } from "utilities/theme";
import { ThemeBuilderForm } from "hooks/use-theme-builder-form-state";

import FileUploader from "containers/admin/clients/client/tab-components/themes-tab/components/file-uploader/file-uploader";
import MultiSelect from "components/forms/multi-select-dropdown/multi-select-dropdown";
import AdobeFontSidebarGroup from "containers/admin/clients/client/tab-components/themes-tab/components/adobe-font-sidebar-group/adobe-font-sidebar-group";
import Link from "components/partials/link/link";
import WarningMessage from "components/forms/warning-message/warning-message";
import NumberInput from "components/forms/number-input/number-input";

// If client has a request to add
// (Brand preset color, form colors, form typography, logo tabs, or misc tabs)
// use ThemeBuilderSidebars_110722.tsx

export interface ThemeSidebarProps {
  form: ThemeBuilderForm;
  readonly: boolean;
}
export const BrandPresetsSidebar = ({ form, readonly }: ThemeSidebarProps) => {
  const systemFontOptions = [
    { value: "arial", label: "Arial" },
    { value: "arialBlack", label: "Arial Black" },
    { value: "bookAntiqua", label: "Book Antiqua" },
    { value: "comicSans", label: "Comic Sans" },
    { value: "courierNew", label: "Courier New" },
    { value: "geneva", label: "Geneva" },
    { value: "georgia", label: "Georgia" },
    { value: "helvetica", label: "Helvetica" },
    { value: "impact", label: "Impact" },
    { value: "lucidaConsole", label: "Lucida Console" },
    { value: "lucidaGrande", label: "Lucida Grande" },
    { value: "monaco", label: "Monaco" },
    { value: "palatino", label: "Palatino" },
    { value: "tahoma", label: "Tahoma" },
    { value: "timesNewRoman", label: "Times New Roman" },
    { value: "trebuchet", label: "Trebuchet" },
    { value: "verdana", label: "Verdana" },
  ];

  function handleSystemFontMultiSelectChange(selectedOptions: any) {
    let chosenFontsArray = [];
    chosenFontsArray = selectedOptions.map((option: any) => {
      return option.label;
    });
    form.handleComplexChange("fontPresets", chosenFontsArray);
  }

  return (
    <Stack data-testid="brand-presets-sidebar" spacing={6}>
      <AdobeFontSidebarGroup form={form} readonly={readonly} />
      <SidebarGroup header="Additional System fonts" testid="additional-system-fonts">
        <MultiSelect
          isDisabled={readonly}
          id="additional-system-fonts-multiselect"
          options={systemFontOptions}
          // @ts-ignore
          value={getMultiSelectInitialValuesFromString(
            systemFontOptions,
            form.values.fontPresets.map((font) => {
              return {
                key: font,
                value: font,
              };
            })
          )}
          onChange={handleSystemFontMultiSelectChange}
        />
      </SidebarGroup>
    </Stack>
  );
};

const BoxShadowInput = ({ ...props }: TextInputProps) => {
  const helperText = props.helperText || (
    <Span>
      Enter a value for the box-shadow{" "}
      <Link isExternal href="https://cssgenerator.org/box-shadow-css-generator.html">
        CSS property
      </Link>
    </Span>
  );
  return <Input helperText={helperText} {...props} />;
};

const ColorInput = ({ ...props }: TextInputProps) => {
  const helperText = props.helperText || (
    <Span>
      This color is used for buttons & links. Check{" "}
      <Link isExternal href="https://webaim.org/resources/contrastchecker/">
        WebAIM
      </Link>{" "}
      to make sure this passes contrast requirements against white.
    </Span>
  );
  return <Input type="color" px={1} helperText={helperText} {...props} />;
};

interface SidebarGroupProps extends BoxProps {
  header: string;
  testid: string;
  stackSpacing?: number;
  previewMode?: boolean;
}
export const SidebarGroup = ({
  header,
  testid,
  stackSpacing = 6,
  previewMode = false,
  children,
}: SidebarGroupProps) => {
  return (
    <Box
      //choosing to disable pointer events on the wrapper here bc the react-file-drop library does not have a prop for disabling the field.
      //setting pointerEvents to "enabled" is because that field needs a string and that is a descriptive string
      //@ts-ignore
      pointerEvents={previewMode ? "none" : null}
      sx={{
        "&:not(:first-of-type)": {
          borderTop: "1px solid",
          borderTopColor: "gray",
          marginTop: 8,
          paddingTop: 4,
        },
      }}>
      <H5 mb={6}>{header}</H5>
      <Stack spacing={stackSpacing} data-testid={testid}>
        {children}
      </Stack>
    </Box>
  );
};

export const ColorSidebar = ({ form, readonly }: ThemeSidebarProps) => {
  return (
    <SidebarGroup header="General" testid="color-sidebar">
      <ColorInput
        label="Page background"
        value={form.values.pageBackgroundColor}
        onChange={form.handleChange("pageBackgroundColor")}
        data-testid="page-background-color-input"
        isDisabled={readonly}
      />
      <ColorInput
        label="Primary color"
        value={form.values.primaryColor}
        onChange={form.handleChange("primaryColor")}
        data-testid="primary-color-input"
        isDisabled={readonly}
        warningMessage="Property not available in email themes"
      />
      <ColorInput
        label="Secondary color"
        value={form.values.secondaryColor}
        onChange={form.handleChange("secondaryColor")}
        data-testid="secondary-color-input"
        isDisabled={readonly}
        warningMessage="Property not available in email themes"
      />
      <ColorInput
        label="Buttons & links"
        value={form.values.buttonAndLinkColor}
        onChange={form.handleChange("buttonAndLinkColor")}
        data-testid="button-link-color-input"
        isDisabled={readonly}
      />
    </SidebarGroup>
  );
};

const handleSelectChange = (
  event: React.ChangeEvent<HTMLSelectElement>,
  form: ThemeBuilderForm,
  fieldName: string
) => {
  const choice = event.target.value;
  form.handleComplexChange(fieldName, choice);
};

export const TypographySidebar = ({ form, readonly }: ThemeSidebarProps) => {
  // ref: https://templates.mailchimp.com/design/typography/#:~:text=Here's%20a%20list%20of%20all,Trebuchet%20MS%2C%20Verdana%2C%20Monaco.

  const fontChoices: string[] = [
    ...WEB_SAFE_FONTS.concat(form.values.fontPresets),
    ...form.values.typekitFonts.map((font) => font.css_stack),
  ];

  const uniqueFontChoices = fontChoices.filter((font, index) => {
    return fontChoices.indexOf(font) === index;
  });

  const FONT_WEIGHTS = [
    { value: "700", label: "Bold (700)" },
    { value: "400", label: "Normal (400)" },
  ];

  const FONT_ELEMENTS = ["h1", "h2", "h3", "body"];

  return (
    <>
      {FONT_ELEMENTS.map((fontElement) => {
        const fontTitle = capitalize(fontElement);
        const testid = `typography-sidebar-${fontElement}`;
        const groupType = " " + fontElement === "body" ? "Text" : "Headings";

        return (
          <SidebarGroup key={fontElement} header={fontTitle + groupType} testid={testid}>
            <FormGroup key={fontElement} spacing={10}>
              <FormControl>
                <WarningMessage
                  message={`${fontTitle} styles not available in email themes`}
                  mt={0}
                />
              </FormControl>
              <Select
                label={`${fontTitle} font family`}
                isReadOnly={readonly}
                placeholder="Select option"
                value={(form.values as any)[`${fontElement}FontFamily`]}
                onChange={(e) => handleSelectChange(e, form, `${fontElement}FontFamily`)}
                data-testid={`${fontElement}-font-family-select`}>
                {uniqueFontChoices
                  .sort((a, b) => a.localeCompare(b)) // Use localeCompare for reliable sorting
                  .map((font) => (
                    <option key={font} value={font} data-testid={`option-${font}`}>
                      {parseTypekitDisplayNameFromCssStack(font)}
                    </option>
                  ))}
              </Select>
              <Select
                label={`${fontTitle} font weight`}
                isReadOnly={readonly}
                placeholder="Select option"
                value={(form.values as any)[`${fontElement}FontWeight`]}
                onChange={form.handleChange(`${fontElement}FontWeight`)}
                data-testid={`${fontElement}-font-weight-select}`}>
                {FONT_WEIGHTS.map(({ value, label }) => (
                  <option
                    key={value}
                    value={value}
                    data-testid={`${fontElement}-font-weight-option-${value}`}>
                    {label}
                  </option>
                ))}
              </Select>
              <Input
                type="number"
                min="1"
                max="2"
                step="0.01"
                label={`${fontTitle} line height`}
                value={(form.values as any)[`${fontElement}LineHeight`]}
                onChange={form.handleChange(`${fontElement}LineHeight`)}
                data-testid={`${fontElement}-line-height-input`}
                isReadOnly={readonly}
              />
              <Input
                type="color"
                px={1}
                label={`${fontTitle} font color`}
                value={(form.values as any)[`${fontElement}Color`]}
                onChange={form.handleChange(`${fontElement}Color`)}
                data-testid={`${fontElement}-font-color-input`}
                isDisabled={readonly}
              />
            </FormGroup>
          </SidebarGroup>
        );
      })}
    </>
  );
};

export const LogosSidebar = ({ form, readonly }: ThemeSidebarProps) => {
  const myHelperText = "Upload an SVG. Maximum file size 500KB.";
  return (
    <Stack data-testid="logos-sidebar" spacing={6}>
      <SidebarGroup
        previewMode={readonly}
        header="Header Logos"
        testid="logos-sidebar"
        pb="6rem"
        borderBottom="1px solid #858B93"
        stackSpacing={2}>
        <P fontWeight={700}>Desktop</P>
        <FileUploader
          helperText={myHelperText}
          currentFile={form.values.headerLogoDesktop ? form.values.headerLogoDesktop : ""}
          fieldName={"headerLogoDesktop"}
          form={form}
        />
        <P fontWeight={700}>Mobile</P>
        <FileUploader
          helperText={myHelperText}
          currentFile={form.values.headerLogoMobile ? form.values.headerLogoMobile : ""}
          fieldName={"headerLogoMobile"}
          form={form}
        />
      </SidebarGroup>
      <SidebarGroup
        previewMode={readonly}
        header="Footer Logos"
        testid="logos-sidebar"
        stackSpacing={2}>
        <P fontWeight={700}>Desktop</P>
        <FileUploader
          helperText={myHelperText}
          currentFile={form.values.footerLogoDesktop ? form.values.footerLogoDesktop : ""}
          fieldName={"footerLogoDesktop"}
          form={form}
        />
        <P fontWeight={700}>Mobile</P>
        <FileUploader
          helperText={myHelperText}
          currentFile={form.values.footerLogoMobile ? form.values.footerLogoMobile : ""}
          fieldName={"footerLogoMobile"}
          form={form}
        />
      </SidebarGroup>
      <SidebarGroup
        previewMode={readonly}
        header="Brand Icons"
        testid="logos-sidebar"
        stackSpacing={2}>
        <P fontWeight={700}>Favicon</P>
        <FileUploader
          helperText={myHelperText}
          currentFile={form.values.favicon ? form.values.favicon : ""}
          fieldName={"favicon"}
          form={form}
        />
      </SidebarGroup>
    </Stack>
  );
};

export const IconsSidebar = ({ form, readonly }: ThemeSidebarProps) => (
  <Stack data-testid="icons-sidebar" spacing={6}>
    <P>Icons</P>
  </Stack>
);

export const MiscSidebar = ({ form, readonly }: ThemeSidebarProps) => {
  return (
    <Stack data-testid="misc-sidebar" spacing={6}>
      <Input
        label="Default border radius"
        value={form.values.defaultBorderRadius}
        helperText="Enter a border radius in pixels."
        onChange={form.handleChange("defaultBorderRadius")}
        isDisabled={readonly}
      />
      <BoxShadowInput
        label="Default box shadow"
        value={form.values.defaultBoxShadow}
        onChange={form.handleChange("defaultBoxShadow")}
        isDisabled={readonly}
      />
      <Input
        label="Default border width"
        value={form.values.defaultBorderWidth}
        helperText="Enter a border width in pixels."
        onChange={form.handleChange("defaultBorderWidth")}
        isDisabled={readonly}
      />
    </Stack>
  );
};

export const ButtonSidebar = ({ form, readonly }: ThemeSidebarProps) => {
  // TODO: Get correct line height options that match BeeEditor

  const LINE_HEIGHTS = [
    { value: "120%", label: "120%" },
    { value: "150%", label: "150%" },
    { value: "180%", label: "180%" },
    { value: "200%", label: "200%" },
  ];

  const parseNumberToPixel = (number: string) => {
    return `${number}px`;
  };

  return (
    <Stack data-testid="button-sidebar" spacing={6}>
      <SidebarGroup header="Button" testid="brand-color-presets">
        <ColorInput
          label="Default button font color"
          value={form.values.buttonFontColor}
          onChange={form.handleChange("buttonFontColor")}
          data-testid="button-font-color-input"
          isDisabled={readonly}
        />
        <NumberInput
          min={0}
          max={60}
          label="Default button border radius"
          value={form.values.buttonBorderRadius}
          helperText="Enter a border radius from 0px to 60px."
          onChange={(value: string) => {
            form.handleComplexChange("buttonBorderRadius", parseNumberToPixel(value));
          }}
          isDisabled={readonly}
        />

        <Select
          label="Default button line height"
          isReadOnly={readonly}
          placeholder="Select option"
          value={form.values.buttonLineHeight}
          onChange={form.handleChange(`buttonLineHeight`)}
          data-testid="button-font-weight-select">
          {LINE_HEIGHTS.map(({ value, label }) => (
            <option key={value} value={value} data-testid={`button-line-height-option-${value}`}>
              {label}
            </option>
          ))}
        </Select>
        <NumberInput
          min={0}
          max={60}
          label="Default button padding X"
          helperText="Enter a button padding from 0px to 60px."
          isDisabled={readonly}
          value={form.values.buttonPaddingHorizontal}
          onChange={(value: string) => {
            form.handleComplexChange("buttonPaddingHorizontal", parseNumberToPixel(value));
          }}
        />
        <NumberInput
          min={0}
          max={60}
          label="Default button padding Y"
          value={form.values.buttonPaddingVertical}
          helperText="Enter a button padding from 0px to 60px."
          onChange={(value: string) => {
            form.handleComplexChange("buttonPaddingVertical", parseNumberToPixel(value));
          }}
          isDisabled={readonly}
        />
        <ColorInput
          label="Default button border color"
          value={form.values.buttonBorderColor}
          onChange={form.handleChange("buttonBorderColor")}
          data-testid="button-border-color-input"
          isDisabled={readonly}
        />
        <NumberInput
          min={0}
          max={30}
          label="Default button border width"
          value={form.values.buttonBorderWidth}
          helperText="Enter a button border width from 0px to 30px."
          onChange={(value: string) => {
            form.handleComplexChange("buttonBorderWidth", parseNumberToPixel(value));
          }}
          isDisabled={readonly}
        />

        <Select
          label="Default button border style"
          isReadOnly={readonly}
          placeholder="Select option"
          value={form.values.buttonBorderStyle}
          onChange={(e) => handleSelectChange(e, form, "buttonBorderStyle")}
          data-testid="buttonBorderStyle-font-family-select">
          {["solid", "dotted", "dashed"].map((borderStyle) => (
            <option key={borderStyle} value={borderStyle} data-testid={`option-${borderStyle}`}>
              {borderStyle}
            </option>
          ))}
        </Select>
        <BoxShadowInput
          label="Default button box shadow"
          value={form.values.buttonBoxShadow}
          onChange={form.handleChange("buttonBoxShadow")}
          isDisabled={readonly}
          warningMessage="Not supported by drag-and-drop editor"
        />
      </SidebarGroup>
    </Stack>
  );
};
