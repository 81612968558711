import React from "react";
import { HeadingProps } from "@chakra-ui/layout";

import { H3, H5 } from "components/partials/typography/typography";

interface LegendProps extends HeadingProps {
  size?: "sm" | "lg";
}

const Legend = ({ size = "lg", ...rest }: LegendProps) => {
  const color = size === "lg" ? "text.heading" : "text.muted";
  return size === "lg" ? <H3 color={color} {...rest} /> : <H5 color="text.muted" {...rest} />;
};

export default Legend;
