import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { AlertStatus } from "@chakra-ui/react";
import * as zod from "zod";

import SecondaryNav from "components/partials/secondary-nav/secondary-nav";
import { ContentWrapper, Sidebar, Content } from "components/partials/layout/layout";
import ThemeBuilderPreviewPane from "containers/admin/clients/client/tab-components/themes-tab/components/theme-builder/theme-builder-preview-pane/theme-builder-preview-pane";

import Builder from "containers/admin/clients/touchpoint/components/builder/builder";
import FormErrors from "components/partials/form-errors/form-errors";
import { AlertMessage } from "components/partials/flash/flash";
import {
  BrandPresetsSidebar,
  ColorSidebar,
  TypographySidebar,
  LogosSidebar,
  IconsSidebar,
  MiscSidebar,
  ButtonSidebar,
} from "containers/admin/clients/client/tab-components/themes-tab/components/theme-builder/theme-builder-sidebars/theme-builder-sidebars";
import toast from "components/partials/toast/toast";

import { useCurrentClient } from "state/ducks/clients";
import { useCurrentUser } from "state/ducks";
import { setCurrentBuilderContent } from "state/ducks/builder-content";
import Theme, { ThemeAttributes } from "models/theme";
import { ThemeIds, TouchpointResponse } from "models/touchpoint-version";

import {
  deeplyTransformEmptyStringToUndefined,
  getErrorMessage,
  isEditableStatus,
  isEqualThemeFormPartial,
  transformEmptyObjectToNull,
} from "utilities";
import { createThemeFromFormState } from "utilities/theme";
import { hasPermission } from "utilities/user";

import { themeSchema, useThemeBuilderFormState } from "hooks/use-theme-builder-form-state";

import { Permission } from "types/auth";
import { ZodFormErrors } from "types";
import { Link } from "types";

interface ThemeBuilderProps {
  theme: Theme;
  baseThemeUrl: string;
  previewMode: boolean;
}

enum ThemeBuilderRoute {
  brandPresets = "/brand-presets",
  color = "/color",
  typography = "/typography",
  logos = "/logos",
  icons = "/icons",
  miscellaneous = "/miscellaneous",
  button = "/button",
}

type ThemeBuilderTab = {
  name: string;
  active: boolean;
  route: ThemeBuilderRoute;
  component: JSX.Element;
};

const ThemeBuilder = ({ theme, baseThemeUrl, previewMode }: ThemeBuilderProps) => {
  const { path, url } = useRouteMatch();
  const client = useCurrentClient();
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  const [isSaved, setIsSaved] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const refreshTouchpoint = ({
    touchpoint,
    status,
    message,
  }: {
    status: AlertStatus;
    message?: Omit<AlertMessage, "status">;
    touchpoint: TouchpointResponse | Theme;
  }) => {
    /**
     * TODO: Move and share with other touchpoint builders
     * The only difference is the cast type here */
    const { attributes } = touchpoint as Theme;

    dispatch(setCurrentBuilderContent(attributes));

    if (message) {
      toast[status]({ ...message });
    }

    if (status === "success") {
      setHasUnsavedChanges(false);
      setIsSaving(false);
    }

    // A network error has occured
    if (status === "error") {
      /**
       * `hasUnsavedChanges` remains true
       * - This prevents an infinite save attempt loop within
       * - checkForUnsavedChanges() in this file
       *
       * `isSaving remains true`
       * - This prevents an infinite save attempt loop within
       * - checkAndSaveChanges() inside Builder.tsx
       */
    }
  };

  const form = useThemeBuilderFormState(theme);

  const thisTheme: ThemeIds = {
    clientId: client.id,
    id: theme.id,
  };

  useEffect(
    function checkForUnsavedChanges() {
      let formAttributes;
      try {
        formAttributes = themeSchema.parse(
          deeplyTransformEmptyStringToUndefined({ ...form.values })
        );
        // Array values not parsing correctly, need to be manually set
        formAttributes.colorPresets = form.values.colorPresets;
      } catch (error) {
        if (error instanceof zod.ZodError) {
          form.setErrors(error.flatten().fieldErrors as ZodFormErrors);
        }
        return;
      }

      !isEqualThemeFormPartial(formAttributes, theme)
        ? setHasUnsavedChanges(true) // kicks off autosave
        : setHasUnsavedChanges(false);

      form.setErrors({});
    },
    // eslint-disable-next-line
    [form.values]
  );

  /**
   * Unused, but themes weren't working anyway.
   * Follow pattern in email-builder and lp-builder to fix
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function saveDraft() {
    if (readonly) {
      return;
    }

    setIsSaving(true);

    let savedDraftAttributes: Partial<ThemeAttributes>;
    try {
      savedDraftAttributes = themeSchema.parse(
        deeplyTransformEmptyStringToUndefined({
          ...form.values,
        })
      );
      savedDraftAttributes = transformEmptyObjectToNull(savedDraftAttributes);
      form.setErrors({});
    } catch (error) {
      if (error instanceof zod.ZodError) {
        form.setErrors(error.flatten().fieldErrors as ZodFormErrors);
      }
      return;
    }
    Theme.replace({
      attributes: { ...theme.attributes, ...savedDraftAttributes },
      ...thisTheme,
    })
      .then((updatedTheme) => {
        refreshTouchpoint({
          touchpoint: updatedTheme,
          status: "success",
        });
      })
      .catch((error) => {
        Theme.find(thisTheme).then((unsavedTheme) => {
          refreshTouchpoint({
            touchpoint: unsavedTheme,
            status: "error",
            message: {
              title: getErrorMessage(error),
              message: "Failed to save theme",
            },
          });
        });
      });
  }

  const clientTheme = createThemeFromFormState(form.values);
  const readonly =
    previewMode ||
    !isEditableStatus(theme.status) ||
    !hasPermission(currentUser, Permission.PERM_THEME_WRITE);

  const tabProps = { form, readonly };
  const previewTabs: ThemeBuilderTab[] = [
    {
      name: "Brand Presets",
      active: true,
      route: ThemeBuilderRoute.brandPresets,
      component: <BrandPresetsSidebar {...tabProps} />,
    },
    {
      name: "Color",
      active: true,
      route: ThemeBuilderRoute.color,
      component: <ColorSidebar {...tabProps} />,
    },
    {
      name: "Typography",
      active: true,
      route: ThemeBuilderRoute.typography,
      component: <TypographySidebar {...tabProps} />,
    },
    {
      name: "Button",
      active: true,
      route: ThemeBuilderRoute.button,
      component: <ButtonSidebar {...tabProps} />,
    },
    /** Inactive tabs discussion:
     * https://dev.azure.com/RVAed/MARKETview%20Education%20Technology/_workitems/edit/13774/)  */
    {
      name: "Logos",
      active: false,
      route: ThemeBuilderRoute.logos,
      component: <LogosSidebar {...tabProps} />,
    },
    {
      name: "Icons",
      active: false,
      route: ThemeBuilderRoute.icons,
      component: <IconsSidebar {...tabProps} />,
    },
    {
      name: "Miscellaneous",
      active: false,
      route: ThemeBuilderRoute.miscellaneous,
      component: <MiscSidebar {...tabProps} />,
    },
  ];

  const activePreviewTabs: ThemeBuilderTab[] = previewTabs.filter((tab) => tab.active);

  const activePreviewLinks: Link[] = activePreviewTabs.map((tab) => ({
    to: url + tab.route,
    text: tab.name,
  }));

  return (
    <Builder
      data-testid="theme-builder"
      apiValue={theme}
      baseUrl={baseThemeUrl}
      previewMode={previewMode}
      permission={Permission.PERM_THEME_WRITE}
      isSaved={isSaved}
      setIsSaved={setIsSaved}
      isSaving={isSaving}>
      <SecondaryNav links={activePreviewLinks} />
      <ContentWrapper>
        <Sidebar width="md" bg="white" py={8} px={6} overflowY="auto">
          <Switch>
            <Redirect exact from={path} to={path + activePreviewTabs[0].route} />
            {activePreviewTabs.map((tab) => (
              <Route path={path + tab.route} key={tab.route}>
                {tab.component}
              </Route>
            ))}
          </Switch>
        </Sidebar>

        <Content backgroundColor="bg.content" fixedContent showBreadcrumbs={false}>
          {Object.entries(form.errors).length > 0 && <FormErrors form={form} />}
          <ThemeBuilderPreviewPane theme={clientTheme} readonly={readonly} />
        </Content>
      </ContentWrapper>
    </Builder>
  );
};

export default ThemeBuilder;
