import { Box, Flex, Stack, Icon, SimpleGrid, BoxProps } from "@chakra-ui/react";
import { MdContentCopy, MdFace } from "react-icons/md";
import { RiPencilFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";

import Card, { CardBody, CardFooter, CardHeader } from "components/partials/card/card";
import { P } from "components/partials/typography/typography";
import Button from "components/forms/button/button";
import toast from "components/partials/toast/toast";
import ColorDisplay, {
  Color,
} from "containers/admin/clients/client/tab-components/themes-tab/components/color-display/color-display";

import { useCurrentUser } from "state/ducks/users";
import { useCurrentClient } from "state/ducks/clients";
import Theme from "models/theme";

import { hasPermission } from "utilities/user";
import { Route as AppRoutes } from "utilities/app-routes";
import { getErrorMessage } from "utilities";

import { Permission } from "types/auth";

interface ThemeCardProps extends BoxProps {
  theme: Theme;
  baseThemeUrl: string;
}

const ThemeCard = ({ theme, baseThemeUrl, ...rest }: ThemeCardProps) => {
  const currentUser = useCurrentUser();
  const client = useCurrentClient();
  const history = useHistory();

  const themeColors: Color[] = [
    {
      label: "Background",
      hex: theme.pageBackgroundColor,
    },
    {
      label: "Primary",
      hex: theme.primaryColor,
    },
    {
      label: "Secondary",
      hex: theme.secondaryColor,
    },
    {
      label: "Button/Links",
      hex: theme.buttonAndLinkColor,
    },
  ];

  function goToTheme() {
    history.push(baseThemeUrl);
  }

  function cloneTheme() {
    Theme.clone({ clientId: client.id, id: theme.id })
      .then((res) =>
        history.push(`${AppRoutes.clients}/${client.id}${AppRoutes.themes}/${res.id}/edit`)
      )
      .catch((err) => {
        toast.error({
          title: "Failed to save email",
          message: getErrorMessage(err?.response?.data),
        });
      });
  }

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  }).format(new Date(theme.lastModifiedDate));

  return (
    <Card
      display={"flex"}
      flexDirection={"column"}
      data-testid={`theme-card-${theme.id}`}
      justifyContent={"space-between"}
      key={theme.id}
      p={2}
      {...rest}>
      {/* <CardShowcase flexDirection="column" flex="1">
        <Image
          src={
            theme.headerLogoDesktop?.url
              ? theme.headerLogoDesktop?.url
              : "https://dummyimage.com/600x400/999/fff.jpg&text=Placeholder"
          }
          alt="Placeholder Image"
          borderTopRadius="lg"
        />
      </CardShowcase> */}

      <Box>
        <CardHeader
          data-testid={`theme-card-header-${theme.name}`}
          heading={theme.name}
          subscript={theme.version ? `v${theme.version}` : undefined}
          status={theme.status}
          pb={3}
        />
        <CardBody pt={0} as={Stack} fontSize="md">
          <SimpleGrid h={8} minChildWidth={1} spacing={0}>
            {themeColors.map(
              (color) =>
                color.hex && (
                  <ColorDisplay
                    key={color.hex}
                    data-testid={"color-display__" + color.hex}
                    color={color}
                  />
                )
            )}
          </SimpleGrid>
          <P data-testid={`theme-${theme.id}-last-updated-${theme.lastModifiedDate}`}>
            Last updated {formattedDate}
            <Icon as={MdFace} ml={4} verticalAlign="bottom" h={6} w={6} />
          </P>
          {/* <P>Used by </P> */}
        </CardBody>
        <CardFooter mx={4} px={0} py={1}>
          <Flex
            justifyContent="space-between"
            color="highlight.200"
            fontSize="lg"
            fontWeight="bold">
            {hasPermission(currentUser, Permission.PERM_THEME_WRITE) && (
              <Button
                leftIcon={<Icon as={MdContentCopy} fontSize="xl" mr={-1} />}
                variant="link"
                onClick={cloneTheme}
                padding="0">
                Clone
              </Button>
            )}
            <Button
              leftIcon={<Icon as={RiPencilFill} fontSize="xl" mr={-1} />}
              variant="link"
              onClick={goToTheme}
              padding="0"
              data-testid="edit-theme-button">
              Edit Details
            </Button>
          </Flex>
        </CardFooter>
      </Box>
    </Card>
  );
};

export default ThemeCard;
