import { useRef, useState, useContext } from "react";
import { AiFillFlag } from "react-icons/ai";
import { MdModeComment } from "react-icons/md";
import { BadgeProps, BoxProps, Flex, HStack } from "@chakra-ui/layout";
import {
  Popover as ChakraPopover,
  PopoverContent,
  PopoverBody,
  Stack,
  PopoverTrigger,
  PopoverCloseButton,
  StackProps,
  Badge,
  Icon,
  Divider,
} from "@chakra-ui/react";

import LegacyButton, { Button } from "components/forms/button/button";
import { CommentsHeader } from "containers/admin/clients/touchpoint/components/comments/comment-header/comment-header";
import CommentsBody from "containers/admin/clients/touchpoint/components/comments/comment-body/comment-body";
import { H4, Span } from "components/partials/typography/typography";
import Textarea from "components/forms/textarea/textarea";
import Checkbox from "components/forms/checkbox/checkbox";

import CommentContext from "contexts/comment-context";

import { useCurrentUser } from "state/ducks";

import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";

interface GeneralCommentsFABProps extends BoxProps {}

export const GeneralCommentsFAB = ({ ...rest }: GeneralCommentsFABProps) => {
  const {
    isFABOpen,
    setIsFABOpen,
    commentData,
    commentsSummary,
    loadComments,
    commentFilter,
    lastActiveCommentId,
    isClientUser,
  } = useContext(CommentContext);

  const [isLoadingMoreComments, setIsLoadingMoreComments] = useState<boolean>(false);
  const hasActionItem = !!commentsSummary?.actionItemCount;
  const triggerRef = useRef<HTMLButtonElement>(null);

  const handleLoadMoreComments = () => {
    // if we're on the last page, return false, else, continue & always return true.
    if (commentData.last) {
      return Promise.resolve(false);
    }
    if (isLoadingMoreComments) {
      return Promise.resolve(true);
    }

    setIsLoadingMoreComments(true);

    return loadComments({
      size: 5,
      page: commentData.page + 1,
      sort: "comment.createdDate,desc",
      filter: commentFilter.value,
    })
      .then((results) => {
        return !results.last;
      })
      .catch(() => {
        return true;
      })
      .finally(() => {
        setIsLoadingMoreComments(false);
      });
  };

  return (
    <ChakraPopover
      data-testid="fab-popover"
      placement="top"
      eventListeners={{ scroll: true, resize: true }}>
      <Flex
        position="fixed"
        bottom={2}
        right={4}
        padding={1}
        justifyContent="end"
        alignItems="end"
        direction="column"
        zIndex="popover">
        <PopoverContent
          borderRadius="2xl"
          borderBottomEndRadius={0}
          position="relative"
          right={12}
          bottom={-9}
          maxH="calc(100vh - 13rem)"
          overflowY="auto">
          <PopoverBody position="relative" padding={0}>
            {commentsSummary?.commentCount > 0 ? (
              <FABContentWrapper>
                <CommentsHeader isClientUser={isClientUser} />
                <CommentsBody
                  loadMoreComments={handleLoadMoreComments}
                  lastActiveCommentId={lastActiveCommentId}
                  isClientUser={isClientUser}
                />
              </FABContentWrapper>
            ) : (
              <EmptyCommentsPanel setIsOpen={setIsFABOpen} isClientUser={isClientUser} />
            )}
          </PopoverBody>
        </PopoverContent>
        <PopoverTrigger>
          <Button
            ref={triggerRef}
            zIndex="popover"
            aria-label={isFABOpen ? "Show Comments" : "Hide Comments"}
            borderRadius="full"
            height="3.5rem"
            paddingX="1.125rem"
            colorScheme={hasActionItem ? "scarlett" : "aqua"}>
            <HStack>
              {hasActionItem ? <AiFillFlag size={20} /> : <MdModeComment size={20} />}
              {!!commentsSummary?.openCount && <Span>{commentsSummary.openCount}</Span>}
            </HStack>
          </Button>
        </PopoverTrigger>
      </Flex>
    </ChakraPopover>
  );
};

interface EmptyCommentsPanelProps {
  setIsOpen: (isOpen: boolean) => void;
  isClientUser: boolean;
}

const EmptyCommentsPanel = ({ setIsOpen, isClientUser }: EmptyCommentsPanelProps) => {
  const { submitNewComment } = useContext(CommentContext);
  const [comment, setComment] = useState<string>("");
  const [isActionItem, setIsActionItem] = useState<boolean>(true);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const currentUser = useCurrentUser();

  const canComment = hasPermission(currentUser, Permission.PERM_COMMENT_WRITE);

  const setInitialState = () => {
    setComment("");
    setIsActionItem(true);
    setIsHidden(false);
    setIsLoading(false);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const payload = {
      actionItem: isActionItem,
      hidden: !isHidden,
      text: comment,
    };
    submitNewComment(payload).finally(() => {
      setInitialState();
      setIsLoading(false);
    });
  };

  const handleCancel = () => {
    setInitialState();
    setIsOpen(false);
  };
  return (
    <FABContentWrapper>
      <FABHeader>
        <Span>There are no comments on this item yet</Span>
      </FABHeader>

      {canComment && (
        <Stack spacing={4}>
          <Textarea
            data-testid="comment-input-main"
            rows={4}
            resize="none"
            placeholder="Add a comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            // need to add ref to the input to focus on it
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSubmit();
              }
            }}
          />
          {!isClientUser && (
            <Stack spacing={1}>
              <Checkbox
                data-testid="comment-input-checkbox-hidden"
                isChecked={isHidden}
                onChange={(e) => setIsHidden(e.target.checked)}
                size="sm"
                label="Visible to client"
              />
              <Checkbox
                data-testid="comment-input-checkbox-actionitem"
                isChecked={isActionItem}
                onChange={(e) => setIsActionItem(e.target.checked)}
                size="sm"
                label="Flag as action item"
              />
            </Stack>
          )}
          <Flex justifyContent="flex-end" pb={4}>
            <LegacyButton
              size="sm"
              onClick={handleCancel}
              mr={4}
              variant="tertiary"
              data-testid="comment-input-cancel-button">
              Cancel
            </LegacyButton>
            <LegacyButton
              size="sm"
              onClick={handleSubmit}
              isLoading={isLoading}
              data-testid="comment-input-submit-button"
              isDisabled={comment.length <= 0}>
              Submit
            </LegacyButton>
          </Flex>
        </Stack>
      )}
    </FABContentWrapper>
  );
};

export const FABContentWrapper = ({ ...rest }: StackProps) => {
  return (
    <Stack
      divider={<FABDivider />}
      sx={{
        "& > *:not([data-no-padding])": {
          px: 6,
          py: 4,
        },
      }}
      {...rest}
    />
  );
};

interface FABHeaderProps extends StackProps {
  actionItemCount?: number;
}
export const FABHeader = ({ actionItemCount = 0, children, ...rest }: FABHeaderProps) => {
  const hasActionItems = actionItemCount > 0;
  return (
    <Stack spacing={4} {...rest}>
      <Flex justify="space-between" align="center">
        <PopoverCloseButton boxSize={8} fontSize="sm" right={1} />
        <H4 color="body">Comments</H4>
        {hasActionItems && <FABActionItemBadge actionItemCount={actionItemCount} mr={4} />}
      </Flex>
      {children}
    </Stack>
  );
};

interface FABActionItemBadgeProps extends BadgeProps {
  actionItemCount: number;
}
export const FABActionItemBadge = ({ actionItemCount, ...rest }: FABActionItemBadgeProps) => {
  return (
    <Badge
      data-testid="comments-header-action-item-count"
      as={Flex}
      alignItems="center"
      justifyContent="space-between"
      color="white"
      fontSize="xs"
      backgroundColor="accent.200"
      px={2}
      py={1}
      rounded="full"
      textTransform="lowercase"
      {...rest}>
      <Icon as={AiFillFlag} boxSize={13} mr="0.375rem" />
      {actionItemCount} action item
      {actionItemCount === 1 ? "" : "s"}
    </Badge>
  );
};

export const FABDivider = () => <Divider data-no-padding borderColor="gray.300" />;
