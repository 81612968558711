import * as React from "react";
import ReactDOM from "react-dom";
import * as FullStory from "@fullstory/browser";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig } from "config/auth-config";

import { App } from "app";
import reportWebVitals from "reportWebVitals";
import * as serviceWorker from "serviceWorker";

import "styles/index.css";

FullStory.init({ orgId: "o-1CBCVA-na1" });

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
  // Pass Public Client Application
  ReactDOM.render(
    <React.StrictMode>
      <App publicClientApplication={msalInstance} />
    </React.StrictMode>,
    document.getElementById("root")
  );
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
