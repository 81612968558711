import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Box,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";

import { Span } from "components/partials/typography/typography";

interface ErrorAlertProps extends AlertProps {
  form?: any;
  title?: string;
  error?: string;
}

const ErrorAlert = ({
  form,
  title = "The following error(s) have been found:",
  error,
}: ErrorAlertProps) => {
  if (!form && !error) {
    return null;
  }
  return (
    <Alert mb={2} status="error" data-testid="error-alert">
      <AlertIcon />
      <Box flex="1">
        {title && <AlertTitle>{title}</AlertTitle>}
        <AlertDescription display="block">
          {form ? (
            <UnorderedList>
              {Object.entries(form.errors).map(([field, errorMessage], ind) => (
                <ListItem key={`${field}-${ind}`}>
                  {field}: {errorMessage}
                </ListItem>
              ))}
            </UnorderedList>
          ) : (
            <Span>{error}</Span>
          )}
        </AlertDescription>
      </Box>
    </Alert>
  );
};

export default ErrorAlert;
