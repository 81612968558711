import { Menu, MenuProps, MenuList } from "@chakra-ui/react";
import MenuItemButton, {
  MenuItemButtonProps,
} from "components/partials/menu-item-button/menu-item-button";

interface ActionsMenuProps extends MenuProps {
  trigger: React.ReactNode;
}
export const ActionsMenu = ({ children, trigger }: ActionsMenuProps) => (
  <Menu>
    {trigger}
    <MenuList zIndex="dropdown" className="!p-1 !shadow-sm">
      {children}
    </MenuList>
  </Menu>
);

export const ActionsMenuItem = {
  Root: MenuItemButton,

  CloneTouchpoint: ({ onClick }: MenuItemButtonProps) => (
    <MenuItemButton onClick={onClick} icon="CopyDocument">
      Clone touchpoint
    </MenuItemButton>
  ),

  EditVersionDetails: ({ onClick }: MenuItemButtonProps) => (
    <MenuItemButton onClick={onClick} icon="Document">
      Edit version details
    </MenuItemButton>
  ),

  EditTouchpoint: ({ onClick, isEmail }: MenuItemButtonProps & { isEmail: boolean }) => (
    <MenuItemButton onClick={onClick} icon="Edit">
      {isEmail ? "Edit email" : "Edit landing page"}
    </MenuItemButton>
  ),

  PreviewTouchpoint: ({ onClick, isEmail }: MenuItemButtonProps & { isEmail: boolean }) => (
    <MenuItemButton onClick={onClick} icon="EyeVisible">
      {isEmail ? "Preview email" : "Preview landing page"}
    </MenuItemButton>
  ),

  CreateDraftFromVersion: ({ onClick }: MenuItemButtonProps) => (
    <MenuItemButton onClick={onClick} icon="NewDocument">
      Create draft from this version
    </MenuItemButton>
  ),

  SendToClient: ({ onClick }: MenuItemButtonProps) => (
    <MenuItemButton onClick={onClick} icon="Airplane">
      Send to Client
    </MenuItemButton>
  ),

  Publish: ({ onClick, isEmail }: MenuItemButtonProps & { isEmail: boolean }) => (
    <MenuItemButton onClick={onClick} icon="Upload">
      {isEmail ? "Publish to Iterable" : "Publish"}
    </MenuItemButton>
  ),

  ArchiveDraft: ({ onClick }: MenuItemButtonProps) => (
    <MenuItemButton onClick={onClick} icon="Archive">
      Archive draft
    </MenuItemButton>
  ),

  ApproveAsClient: ({ onClick }: MenuItemButtonProps) => (
    <MenuItemButton onClick={onClick} icon="CheckCircle">
      Approve as a Client
    </MenuItemButton>
  ),
};
