import { useState } from "react";
import { HStack, Menu, MenuButton, MenuList, MenuItem, Box, useDisclosure } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import PaginatedTable from "components/partials/paginated-table/paginated-table";
import { StatusDisplay } from "components/partials/status-display/status-display";
import {
  TableEditButton,
  TableMoreButton,
  TableViewButton,
} from "components/table/table-icon-button/table-icon-button";
import VersionEditModal, {
  FormType,
  onSubmitFormProps,
} from "components/modals/version-edit-modal/version-edit-modal";
import { StatusColumn } from "components/table/table-status-column/table-status-column";
import ConfirmArchiveModal from "components/modals/confirm-archive-modal/confirm-archive-modal";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";
import toast from "components/partials/toast/toast";

import { useCurrentClient } from "state/ducks/clients";
import { useCurrentTheme } from "state/ducks/themes";
import { setCurrentBuilderContent } from "state/ducks/builder-content";
import { useCurrentUser } from "state/ducks";
import Theme from "models/theme";

import { hasPermission } from "utilities/user";
import { Route as AppRoute } from "utilities/app-routes";
import { getErrorMessage, sliceUrlParameter } from "utilities";

import { Permission } from "types/auth";
import { ContentStatus } from "types";
import { PaginatedRequestOptions } from "types/pagination";
import { TableRow } from "components/table/table";
import { TableCell } from "components/table/table-cell/table-cell";

const ThemeVersionHistoryTable = ({ baseThemeUrl }: { baseThemeUrl: string }) => {
  const currentClient = useCurrentClient();
  const currentUser = useCurrentUser();
  const theme = useCurrentTheme();
  const history = useHistory();
  const editVersionModal = useDisclosure();
  const confirmPublishModal = useDisclosure();
  const confirmArchiveModal = useDisclosure();
  const newDraftModal = useDisclosure();
  const dispatch = useDispatch();

  const [currentActionItem, setCurrentActionItem] = useState<Theme>();
  const [shouldRefreshTable, setShouldRefreshTable] = useState<boolean>(false);
  const [nextMinorMajorVersions, setNextMinorMajorVersions] = useState<string[]>();

  // One can only create a theme draft if a theme draft doesn't already exist
  const [canCreateDraft, setCanCreateDraft] = useState<boolean>(true);

  const refreshTable = () => setShouldRefreshTable(true);

  const loadVersionHistory = (options: PaginatedRequestOptions) => {
    // Wipe the builder content on data refresh
    dispatch(setCurrentBuilderContent(null));
    return Theme.versionHistory({
      clientId: currentClient.id,
      id: theme.id,
      options: options,
    }).then((response) => {
      if (
        response.items.some((theme) => {
          return theme.status === ContentStatus.DRAFT;
        })
      ) {
        setCanCreateDraft(false);
      } else {
        setCanCreateDraft(true);
      }
      return response;
    });
  };

  const handleEditThemePressed = (id: string) => {
    history.push(`${baseThemeUrl}${AppRoute.themeBuilder}/${id}`);
  };

  const handlePreviewThemePressed = (id: string) => {
    history.push(`${baseThemeUrl}${AppRoute.themePreview}/${id}`);
  };

  const handleNewDraftSubmit = ({ id, version, versionNotes }: onSubmitFormProps) => {
    if (id) {
      Theme.createDraft({
        clientId: currentClient.id,
        id: id,
        version: version,
        versionNotes: versionNotes,
      })
        .then(() => {
          refreshTable();
        })
        .catch((err) => {
          toast.error({
            title: "Failed to create draft",
            message: getErrorMessage(err?.response?.data),
          });
        });
    }
    newDraftModal.onClose();
  };

  const handlePublishTheme = (id?: string) => {
    if (id) {
      Theme.publish({ id, clientId: currentClient.id })
        .then(() => {
          refreshTable();
          toast.success({
            title: "Theme published",
          });
        })
        .catch((err) => {
          toast.error({
            title: "Failed to publish theme",
            message: getErrorMessage(err?.response?.data),
          });
        });
    }
    confirmPublishModal.onClose();
  };

  const handleArchiveTheme = (id?: string) => {
    if (id) {
      Theme.archive({ clientId: currentClient.id, id: id })
        .then(() => {
          refreshTable();
          toast.success({
            title: "Theme archived",
          });
        })
        .catch((err) => {
          toast.error({
            title: "Failed to archive theme",
            message: getErrorMessage(err?.response?.data),
          });
        });
    }
    confirmArchiveModal.onClose();
  };

  const handleCloneVersion = (id: string) => {
    Theme.clone({ clientId: currentClient.id, id })
      .then((res) => {
        toast.success({
          title: "Theme cloned",
        });
        history.push({
          pathname: `${sliceUrlParameter(baseThemeUrl, 1)}/${res.id}`,
        });
      })
      .catch((err) => {
        toast.error({
          title: "Failed to clone theme",
          message: getErrorMessage(err?.response?.data),
        });
      });
  };

  const handleCreateDraftFromVersion = (id: string) => {
    // 1. Fetched available next version #s using currentActionItem
    Theme.nextVersions({
      clientId: currentClient.id,
      id: id,
    })
      .then((response) => {
        let { nextMinorVersion, nextMajorVersion } = response.data;
        setNextMinorMajorVersions([nextMinorVersion, nextMajorVersion]);
      })
      .then(() => newDraftModal.onOpen())
      .catch((err) => {
        toast.error({
          title: "Failed to create draft",
          message: getErrorMessage(err?.response?.data),
        });
      });
  };

  const handleEditVersion = ({
    id,
    version,
    versionNotes,
  }: {
    id?: string;
    version: string;
    versionNotes: string;
  }) => {
    if (id) {
      Theme.editVersionDetails({
        clientId: currentClient.id,
        id: id,
        version: version,
        versionNotes: versionNotes,
      })
        .then(() => {
          refreshTable();
        })
        .catch((err) => {
          toast.error({
            title: "Failed to edit version",
            message: getErrorMessage(err?.response?.data),
          });
        });
    }
    editVersionModal.onClose();
  };

  return (
    <>
      <PaginatedTable
        headers={["Version", "Description", "Status", "Actions"]}
        fetchPage={loadVersionHistory}
        shouldRefresh={shouldRefreshTable}
        setShouldRefresh={setShouldRefreshTable}>
        {(theme) => {
          const isDraft = theme.status === ContentStatus.DRAFT;
          return (
            <TableRow>
              {/* Version */}
              <StatusColumn
                id={theme.id}
                status={theme.status}
                version={theme.version}
                lastModifiedDate={
                  theme.status === ContentStatus.PUBLISHED
                    ? theme.publishedAt ?? theme.lastModifiedDate
                    : theme.lastModifiedDate
                }
              />

              {/* Description */}
              <TableCell data-testid={`${theme.id}-table-description`}>
                {theme.versionNotes}
              </TableCell>

              {/* Status */}
              <TableCell>
                <StatusDisplay status={theme.status} liveText="Published" />
              </TableCell>

              {/* Actions */}
              <TableCell>
                <HStack justifyContent={"flex-end"}>
                  {isDraft && (
                    <TableEditButton
                      title="Edit Theme"
                      aria-label="Edit Theme"
                      onClick={() => handleEditThemePressed(theme.id)}
                    />
                  )}
                  <TableViewButton
                    title="Preview Theme"
                    aria-label="Preview Theme"
                    onClick={() => handlePreviewThemePressed(theme.id)}
                  />
                  <Box>
                    <Menu isLazy>
                      <TableMoreButton
                        data-testid={"open-menu-button"}
                        as={MenuButton}
                        title="More actions"
                        aria-label="More actions"
                        sx={{ "& > span": { display: "flex", justifyContent: "center" } }}
                      />
                      <MenuList>
                        {isDraft && (
                          <>
                            <MenuItem
                              data-testid={"menu-edit-version-button"}
                              onClick={() => {
                                setCurrentActionItem(theme);
                                editVersionModal.onOpen();
                              }}>
                              Edit version details
                            </MenuItem>
                            {hasPermission(currentUser, Permission.PERM_THEME_WRITE) && (
                              <MenuItem
                                data-testid={"menu-edit-button"}
                                onClick={() => handleEditThemePressed(theme.id)}>
                                Edit theme
                              </MenuItem>
                            )}
                            {hasPermission(currentUser, Permission.PERM_THEME_PUBLISH) && (
                              <MenuItem
                                data-testid={"menu-publish-button"}
                                onClick={() => {
                                  setCurrentActionItem(theme);
                                  confirmPublishModal.onOpen();
                                }}>
                                Publish theme
                              </MenuItem>
                            )}
                            <MenuItem
                              data-testid={"menu-archive-button"}
                              onClick={() => {
                                setCurrentActionItem(theme);
                                confirmArchiveModal.onOpen();
                              }}>
                              Archive theme
                            </MenuItem>
                          </>
                        )}
                        <MenuItem
                          data-testid={"menu-preview-version-button"}
                          onClick={() => handlePreviewThemePressed(theme.id)}>
                          Preview theme
                        </MenuItem>
                        {canCreateDraft && theme.status === ContentStatus.PUBLISHED && (
                          <MenuItem
                            data-testid={"menu-create-draft-button"}
                            onClick={() => {
                              setCurrentActionItem(theme);
                              handleCreateDraftFromVersion(theme.id);
                            }}>
                            Create draft from this version
                          </MenuItem>
                        )}
                        <MenuItem
                          data-testid={"menu-clone-version-button"}
                          onClick={() => handleCloneVersion(theme.id)}>
                          Clone to new theme
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                </HStack>
              </TableCell>
            </TableRow>
          );
        }}
      </PaginatedTable>
      <VersionEditModal
        {...newDraftModal}
        baseUrl={baseThemeUrl}
        parentName={theme.name}
        formType={FormType.NEW_VERSION}
        onSubmit={handleNewDraftSubmit}
        version={currentActionItem?.version || "1.0"}
        nextMinorMajorVersions={nextMinorMajorVersions}
        name={currentActionItem?.name}
        id={currentActionItem?.id}
      />
      <VersionEditModal
        {...editVersionModal}
        id={currentActionItem?.id}
        placeholderNotes={currentActionItem?.versionNotes}
        baseUrl={baseThemeUrl}
        parentName={theme.name}
        formType={FormType.EDIT}
        version={currentActionItem?.version || "1.0"}
        name={currentActionItem?.name}
        onSubmit={handleEditVersion}
        nextMinorMajorVersions={nextMinorMajorVersions}
        helperText={"Edit the version description"}
        saveText={"Save"}
      />
      <ConfirmArchiveModal
        {...confirmArchiveModal}
        currentTouchpoint={currentActionItem}
        onSubmit={() => handleArchiveTheme(currentActionItem?.id)}
      />
      <ConfirmationModal
        {...confirmPublishModal}
        onConfirm={() => {
          handlePublishTheme(currentActionItem?.id);
        }}
        headline={"Are you sure you want to publish this theme"}
        message={"This will replace the current published version. This action cannot be undone"}
        confirmButtonText={"Yes, publish theme"}
      />
    </>
  );
};

export default ThemeVersionHistoryTable;
