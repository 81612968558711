import {
  Flex,
  PopoverProps as ChakraPopoverProps,
  Popover as ChakraPopover,
  PopoverArrow,
  PopoverBody,
  PopoverContent as ChakraPopoverContent,
  PopoverTrigger as ChakraPopoverTrigger,
  VStack,
  CSSObject,
  Portal,
} from "@chakra-ui/react";

export interface PopoverProps extends ChakraPopoverProps {
  triggerElement: React.ReactNode;
  triggerPosition?: CSSObject;
  sx?: CSSObject;
  children?: React.ReactNode;
  arrow?: boolean;
  padding?: number;
  borderRadius?: string;
  placement?: "bottom" | "top" | "top-end" | "bottom-start";
  isOpen: boolean;
  onClose: () => void;
  popoverWidth?: string;
}

const Popover = ({
  children,
  arrow = true,
  matchWidth = true,
  padding = 3,
  placement = "bottom",
  popoverWidth, // "inherit" will match width of parent
  borderRadius = "md",
  isOpen,
  onClose,
  triggerElement,
  triggerPosition,
  variant = "responsive",
  closeOnBlur,
  sx,
  ...rest
}: PopoverProps) => {
  return (
    <ChakraPopover
      variant={variant}
      placement={placement}
      closeOnBlur={closeOnBlur}
      onClose={onClose}
      isOpen={isOpen}
      arrowSize={13}
      matchWidth={matchWidth}
      {...rest}>
      <ChakraPopoverTrigger>
        <Flex sx={triggerPosition}>{triggerElement}</Flex>
      </ChakraPopoverTrigger>
      <Portal>
        <ChakraPopoverContent
          boxShadow="md"
          borderRadius={borderRadius}
          width={popoverWidth}
          sx={sx}>
          {arrow && <PopoverArrow />}
          <PopoverBody
            as={VStack}
            spacing={0}
            boxShadow="md"
            padding={padding}
            borderRadius={borderRadius}>
            {children}
          </PopoverBody>
        </ChakraPopoverContent>
      </Portal>
    </ChakraPopover>
  );
};

export default Popover;
