import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { currentCampaignSelector, setCurrentCampaign } from "state/ducks/campaigns";
import { useCurrentClient } from "state/ducks/clients";
import Campaign from "models/campaign";

import { Route } from "utilities/app-routes";

const CampaignProvider: React.FC = ({ children }) => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const history = useHistory();
  const campaign = useSelector(currentCampaignSelector);
  const dispatch = useDispatch();
  const client = useCurrentClient();

  useEffect(
    function mountCampaign() {
      if (campaignId && client.id && campaign?.id !== campaignId) {
        Campaign.find(client.id, campaignId)
          .then((campaign) => dispatch(setCurrentCampaign(campaign.attributes)))
          .catch(() => history.push(Route.notFound));
      }
    },
    [campaign?.id, campaignId, client.id, dispatch, history]
  );

  useEffect(() => {
    return () => {
      dispatch(setCurrentCampaign(null));
    };
  }, [dispatch]);

  return <>{campaign && campaign.id === campaignId ? children : null}</>;
};

export default CampaignProvider;
