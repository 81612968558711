import PaginatedTable from "components/partials/paginated-table/paginated-table";
import RouterLink from "components/partials/router-link/router-link";
import Link from "components/partials/link/link";
import { StatusDisplay } from "components/partials/status-display/status-display";

import { useCurrentClient } from "state/ducks/clients";
import { useCurrentCampaign } from "state/ducks/campaigns";
import Touchpoint from "models/touchpoint";

import { baseIterableUrl, sortAlphabetically } from "utilities";

import { PaginatedRequestOptions, PaginatedResponse } from "types/pagination";
import { TouchpointType } from "types/touchpoint";
import { TableRow } from "components/table/table";
import { TableCell } from "components/table/table-cell/table-cell";

const EmailTable = ({ creativeIndexUrl }: { creativeIndexUrl: string }) => {
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();

  const loadAllEmails = (
    options: PaginatedRequestOptions
  ): Promise<PaginatedResponse<Touchpoint>> => {
    return Touchpoint.all({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      type: TouchpointType.EMAIL,
      options: { ...options, sort: "name" },
    }).then((results) => {
      results.items = sortAlphabetically(results.items);
      return results;
    });
  };
  return (
    <PaginatedTable
      headers={["Email", "Subject line", "Iterable Email", "Client Review Status"]}
      fetchPage={loadAllEmails}>
      {(touchpoint: Touchpoint) => {
        return (
          <TableRow>
            <TableCell>
              <RouterLink
                data-testid={`emailtable-name-${touchpoint.id}`}
                to={`${creativeIndexUrl}/${touchpoint.id}`}>
                {touchpoint.name}
              </RouterLink>
            </TableCell>
            <TableCell data-testid={`emailtable-subject-${touchpoint.id}`}>
              {touchpoint.subjectLine}
            </TableCell>
            <TableCell data-testid={`emailtable-workflow-${touchpoint.id}`}>
              <Link
                href={`${baseIterableUrl}${touchpoint.iterableTemplateId}`}
                target="blank"
                style={{ textDecoration: "underline" }}>
                {touchpoint.iterableTemplateId &&
                  `Iterable Email #${touchpoint.iterableTemplateId}`}
              </Link>
            </TableCell>
            <TableCell>
              {touchpoint.workflowStatus && (
                <StatusDisplay
                  status={touchpoint.workflowStatus}
                  liveText={"Published"}
                  archivedText={"Archived"}
                  draftText={"Draft"}
                />
              )}
            </TableCell>
          </TableRow>
        );
      }}
    </PaginatedTable>
  );
};

export default EmailTable;
