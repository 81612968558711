import { Id, ToastOptions as ToastifyOptions, toast as toastify } from "react-toastify";

import Icons from "assets";

/** Requires *at least* one of `title` or `message` */
type ToastMessaging =
  | {
      title: string;
      message?: string;
    }
  | {
      title?: string;
      message: string;
    };

type ToastProps = ToastMessaging & React.PropsWithChildren<{}>;

type ToastOptions = ToastifyOptions & ToastProps;

type ToastService = {
  (props: ToastOptions): Id;
} & typeof toastify;

const toast = (props: ToastOptions): Id => toastify(<Toast {...props} />, { ...props });

Object.keys(toastify).forEach((key) => {
  (toast as any)[key] = (toastify as any)[key];
});

toast.error = (props: ToastOptions): Id =>
  toastify.error(<Toast {...props} />, {
    ...props,
    icon: <Icons.ErrorIndicator />,
  });

toast.info = (props: ToastOptions): Id =>
  toastify.info(<Toast {...props} />, {
    ...props,
    icon: <Icons.InfoIndicator />,
  });

toast.success = (props: ToastOptions): Id =>
  toastify.success(<Toast {...props} />, {
    ...props,
    icon: <Icons.SuccessIndicator />,
  });

toast.warning = (props: ToastOptions): Id =>
  toastify.warning(<Toast {...props} />, {
    ...props,
    icon: <Icons.WarningIndicator />,
  });

const Toast = ({ title, message, children }: ToastProps) => {
  return (
    <div className="space-y-4">
      <div className="text-default space-y-1 leading-6">
        {title && <p className="font-bold">{title}</p>}
        {message && <p>{message}</p>}
      </div>
      {children && <div>{children}</div>}
    </div>
  );
};

export default toast as ToastService;
