import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";

import LandingPageForm from "containers/admin/clients/touchpoint/components/lp-form/lp-form";
import { H2 } from "components/partials/typography/typography";
import PageContentHeader from "components/partials/page-content-header/page-content-header";
import VersionEditModal, {
  FormType,
  onSubmitFormProps,
} from "components/modals/version-edit-modal/version-edit-modal";
import toast from "components/partials/toast/toast";
import { useCurrentClient, useCurrentCampaign } from "state/ducks";
import { Unpersisted } from "models/model";
import { Touchpoint } from "models";
import TouchpointVersion from "models/touchpoint-version";
import { TouchpointAttributes } from "models/touchpoint";

import { getErrorMessage } from "utilities";
import { Route as AppRoute } from "utilities/app-routes";

import { usePagePermission } from "hooks/use-page-permission";
import { usePermissionBlacklist } from "hooks/use-permission-blacklist";

import { Permission } from "types/auth";
import { TouchpointTypeLabel } from "types/touchpoint";

export type createTouchpointActionType = "toBuilder" | "toTouchpoint";

const LPNewPage = ({ baseCreativeUrl }: { baseCreativeUrl: string }) => {
  const history = useHistory();
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const createNewDraftModal = useDisclosure();
  const [newTouchpointData, setNewTouchpointData] = useState<Touchpoint>();
  usePermissionBlacklist([Permission.PERM_CLIENT_USER]);
  usePagePermission(Permission.PERM_TOUCHPOINT_WRITE);

  const createLandingPage = (
    attributes: Unpersisted<TouchpointAttributes>,
    actionType: createTouchpointActionType
  ) => {
    Touchpoint.create({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      attributes: attributes,
    })
      .then((results) => {
        if (actionType === "toTouchpoint") {
          toast.success({
            title: `${TouchpointTypeLabel.LP_TOUCHPOINT} created`,
          });
          history.push({
            pathname: baseCreativeUrl,
          });
        }
        if (actionType === "toBuilder") {
          setNewTouchpointData(results);
          createNewDraftModal.onOpen();
        }
      })
      .catch((err) => {
        toast.error({
          title: `Failed to create ${TouchpointTypeLabel.LP_TOUCHPOINT}`,
          message: getErrorMessage(err?.response?.data),
        });
      });
  };

  const createDraftSubmit = ({ version, versionNotes, visibleToClient }: onSubmitFormProps) => {
    newTouchpointData &&
      TouchpointVersion.create({
        campaignId: currentCampaign.id,
        clientId: currentClient.id,
        touchpointId: newTouchpointData.id,
        version: version,
        versionNotes: versionNotes,
        visibleToClient: visibleToClient,
      }).then((response) => {
        toast.success({
          title: `${TouchpointTypeLabel.LP_TOUCHPOINT} created`,
        });
        history.push({
          pathname:
            baseCreativeUrl +
            "/" +
            newTouchpointData.id +
            AppRoute.versions +
            "/" +
            response.id +
            AppRoute.lpBuilder,
        });
      });
  };

  const cancelCreateLandingPage = () => {
    // Redirect to touchpoints page
    history.push(baseCreativeUrl);
  };

  return (
    <>
      <PageContentHeader>
        <H2 className="pt-5">Add New {TouchpointTypeLabel.LP_TOUCHPOINT}</H2>
      </PageContentHeader>
      <LandingPageForm
        data-testid="lp-new-page"
        onSubmit={createLandingPage}
        onCancel={cancelCreateLandingPage}
        baseCreativeUrl={baseCreativeUrl}
      />
      <VersionEditModal
        {...createNewDraftModal}
        baseUrl={baseCreativeUrl}
        parentName={currentCampaign.name}
        name={newTouchpointData?.name}
        formType={FormType.NEW}
        onSubmit={createDraftSubmit}
        version="1.0"
        placeholderNotes={"Initial draft"}
      />
    </>
  );
};

export default LPNewPage;
